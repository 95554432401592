<template>
  <v-card class="mt-3 card-size" flat>
    <v-form ref="form" @submit.prevent="registerUser">
      <v-row class="pl-2 px-6">
        <v-col class="mt-3" cols="6">
          <h3 class="page-title">USER REGISTRATION</h3>
          <h5 class="light-gray-text">Register users details according to your requirements.</h5>
          <v-card-title>
            
          </v-card-title>
        </v-col>
        <v-col class="px-4 mt-5" cols="6">
          <v-row class="tool-bar-style">
              <v-btn class="red-text" size="x-large" variant="text" @click="createUserRole()">
                <v-icon>mdi-account-star</v-icon>&nbsp;&nbsp;CREATE USER ROLE
              </v-btn>
              <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
              <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
                <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
              </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-card class="px-2 pt-2 mx-1 leads-details-card" elevation="3">
        <h5 class="card-two-title px-3 mt-2 mb-4">USERS REGISTRATION</h5>
        <v-divider></v-divider>

        <v-row class="pt-6">
          <v-col class="px-6" cols="6">
            
            <v-card class="pt-2 card-hight-fixed" flat>
              <v-col cols="11">
              <v-text-field
                v-model="user.fullName"
                class="mb-2"
                label="Full Name *"
                placeholder="Type full name here"
                required
                variant="outlined"
              ></v-text-field>
              <v-text-field
                v-model="user.userName"
                class="mb-2"
                label="User Name *"
                placeholder="Type user name here"
                required
                variant="outlined"
              ></v-text-field>

              <v-select
                v-model="user.userType"
                :items="userTypes"
                :rules="[v => !!v || 'User role is required']"
                class="mb-2"
                clearable
                item-title="name"
                item-value="id"
                label="User Type *"
                placeholder="Select User Type Here"
                required
                variant="outlined"
                @update:modelValue="openDashBoardSeletDialog"
              ></v-select>

                <v-select
                    v-model="user.geofence"
                    :items="geoFences"
                    class="mb-2"
                    label="Select Geofence *"
                    multiple
                    placeholder="Select geofence access"
                    variant="outlined"
                ></v-select>



              <v-text-field
                v-model="user.emailAddress"
                :rules="emailRules"
                class="mb-2"
                label="Email Address *"
                placeholder="Type email here"
                required
                variant="outlined"
              ></v-text-field>

                <div v-for="(phoneNo, index) in phoneNumbers" :key="index">
                  <v-row class="mb-2">
                    <v-col cols="8">
                      <v-text-field v-model="phoneNumbers[index]" :rules="phoneRules" label="Phone Number *" placeholder="Create sub category" variant="outlined"></v-text-field>
                    </v-col>
                    <v-col class="align-center justify-center" cols="auto">
                      <v-btn class="green-text" density="default" icon="mdi-plus-thick" variant="outlined" @click="addPhoneNo"></v-btn>
                    </v-col>
                    <v-col class="align-center justify-center" cols="auto">
                      <v-btn v-if="phoneNumbers.length > 1" class="red-text-btn" density="default" icon="mdi-minus-thick" variant="outlined" @click="removePhoneNo(index)"></v-btn>
                    </v-col>
                  </v-row>
                </div>
              
              <v-text-field
                v-model="user.nic"
                :rules="nicRules"
                class="mb-2"
                label="NIC *"
                placeholder="Type NIC number here"
                required
                variant="outlined"
              ></v-text-field>

              <v-select
                v-model="user.gender"
                :items="gender"
                class="mb-2"
                label="Gender *"
                placeholder="Select Gender"
                required
                variant="outlined"
              ></v-select>
            </v-col>
            </v-card>
          </v-col>
          <v-col class="px-6" cols="6">
            <v-card class="pt-2 card-hight-fixed" flat>
              <v-col cols="11">

              <v-select
                v-model="user.province"
                :items="provinces"
                :loading="mProvinceLoading"
                class="mb-2"
                item-text="label"
                item-value="value"
                label="Province *"
                placeholder="Select province"
                variant="outlined"
                @update:modelValue="getAllDistrictAccProv"
              ></v-select>

              <v-select
                v-model="user.district"
                :items="districts"
                :loading="mDistrictLoading"
                class="mb-2"
                item-text="label"
                item-value="value"
                label="District *"
                placeholder="Select district"
                variant="outlined"
                @update:modelValue="getAllDsd"
              ></v-select>

              <v-select
                v-model="user.dsd"
                :items="dsdList"
                :loading="mDSDLoading"
                class="mb-2"
                item-text="label"
                item-value="value"
                label="DSD *"
                placeholder="Select divisional"
                variant="outlined"
                @update:modelValue="getAllGnd"
              ></v-select>

              <v-select
                v-model="user.gnd"
                :items="gndList"
                :loading="mGNDLoading"
                class="mb-2"
                label="GND *"
                placeholder="Select GND"
                required
                variant="outlined"
              ></v-select>

              <v-textarea
                v-model="user.address"
                class="mb-2"
                label="Address *"
                placeholder="Type user address here"
                required
                rows="2"
                variant="outlined"
              ></v-textarea>

              <v-card flat>
                <div
                  class="drop-area mb-6"
                  @click="triggerFileInput"
                  @drop.prevent="onDrop"
                  @dragover.prevent="onDragOver"
                >
                  <input
                    ref="fileInput"
                    accept="image/*"
                    class="hidden-file-input"
                    multiple
                    type="file"
                    @change="onFileChange"
                  />
                  <div
                    v-if="previewImages.length > 0"
                    class="image-card-container"
                  >
                  <v-card class="image-card">
                    <v-img
                      :src="previewImages[0]"
                      alt="Image preview"
                      class="image-preview"
                    ></v-img>
                    <v-btn
                      class="remove-icon"
                      icon
                      size="x-small"
                      @click.stop="removeImage(0)"
                    >
                      <v-icon color="red" size="small">mdi-delete</v-icon>
                    </v-btn>
                  </v-card>
                  </div> 
                  <div
                    v-if="previewImages.length === 0"
                    class="placeholder-container"
                  >
                    <v-icon size="36">mdi-cloud-upload</v-icon>
                    <span>Drag your photo here OR click to browse from device</span>
                  </div>
                </div>
              </v-card>
            </v-col>
            </v-card>
          </v-col>
        </v-row>
        <v-divider class="divider-two-style"></v-divider>
        <v-row class="margin-bottomn-fix pa-3 mb-2">
          <v-spacer></v-spacer>
          <v-btn class="red-background-text mr-3" type="submit"  variant="outlined">REGISTER USER</v-btn>
          <v-btn class="gray-background-text" variant="outlined" @click="cancel()">CANCEL</v-btn>
        </v-row> 
      </v-card>
    </v-form>

    <v-dialog v-model="dashboardSelectDialog"  max-height="750" max-width="500" persistent>
      
        <v-card class="pa-1">
          <v-row class="rowClr mb-1 mt-3 mx-3" dense>
            <v-col cols="12" md="9">
              <v-card-title>SET PREMISSION</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="dashboardSelectDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3"></v-divider>

            <v-col cols="12" md="12">
            
            <h5 class="card-two-title px-3 mt-6 mb-4">Setup Permissions</h5>
            <v-card class="pt-2 mb-3 mx-1 mt-1 card-hight-fixed">
              <v-row v-for="(item, index) in permissionsItems" :key="item.id" class="align-center no-gutters">
                <v-row class="ml-3">
                  <v-col class="d-flex margin-up-down-two" cols="auto">
                    <v-switch
                      v-model="item.isOn"
                      :color="item.isOn ? 'red' : 'grey'"
                      class="mr-1"
                      @change="handleSwitchChange(item)"
                    ></v-switch>
                  </v-col>
                  <v-col class="d-flex" cols="auto">
                    <v-card-text :class="{ 'red-text': item.isOn }" class="ml-3 mr-1">{{ item.premission_dashboard }}</v-card-text>
                  </v-col>
                </v-row>
                <v-col v-if="item.isOn" class="pl-10" cols="12">
                  <v-row class="margin-up-down">
                    <v-col class="d-flex" cols="auto">
                      <v-switch
                        v-model="item.permissions.read"
                        class="mr-1"
                        color="red"
                        @change="handlePermissionChange(item, 'read')"
                      ></v-switch>
                      <v-card-text class="mx-1">Read</v-card-text>
                    </v-col>
                    <v-col class="d-flex" cols="auto">
                      <v-switch
                        v-model="item.permissions.write"
                        class="mx-1"
                        color="red"
                        @change="handlePermissionChange(item, 'write')"
                      ></v-switch>
                      <v-card-text class="mr-1">Write</v-card-text>
                    </v-col>
                    <v-col class="d-flex" cols="auto">
                      <v-switch
                        v-model="item.permissions.delete"
                        class="mx-1"
                        color="red"
                        @change="handlePermissionChange(item, 'delete')"
                      ></v-switch>
                      <v-card-text class="mr-1">Delete</v-card-text>
                    </v-col>
                  </v-row>
                </v-col>
                <v-divider v-if="index < permissionsItems.length - 1"></v-divider>
              </v-row>
          </v-card>
          </v-col>

           

          <v-card>
            
            <v-card-text>
             
                  <v-divider></v-divider>
                <v-card-actions class="mr-1">
                  <v-spacer></v-spacer>
                  <v-btn class="bg-grey-lighten-2" variant="outlined" @click="dashboardSelectDialog = false">CANCEL</v-btn>
                </v-card-actions>
            </v-card-text>
          </v-card>
          
        </v-card>
    
      </v-dialog>


    <!-- Snackbar to show notifications -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
  </v-card>
</template>
    
<script>
import axios from 'axios';

export default {
  name: 'UserRegistration',
  data() {
    return {
      permissionsItems: [],
      dashboardSelectDialog:false,
      user: {
        fullName: '',
        userName: '',
        userType: '',
        geofence: [],
        emailAddress: '',
        phoneNumber: [""],
        nic: '',
        gender: '',
        province: '',
        district: '',
        dsd: '',
        gnd: '',
        address: '',
        image: [],
        profilePhoto: null,
      },
      selectedRoleId:'',
      selectedRoleName:'',

      geoFences : ["Route Plan Geofence", "My Route Geofence", "Collection Geofence", "Sales Geofence"],

      phoneRules: [
        v => !!v || 'Phone number is required',
        v => /^\d{10}$/.test(v) || 'Must be a valid 10-digit number'
      ],
      emailRules: [
        v => !!v || 'Email is required',
        v => /.+@.+\..+/.test(v) || 'Must be a valid email'
      ],
      nicRules: [
        v => !!v || 'NIC is required',
        v => /^[0-9]{9}[vVxX]$|^[0-9]{12}$/.test(v) || 'Must be a valid NIC number'
      ],

      e1: false,
      e2: false,
      previewImages: [],

      snackAlert: {
        snackbar: false, // Snackbar visibility
        snackColor: "", // Snackbar color
        snackMessage: "", // Snackbar message
        snackBarRight: false, // Snackbar position right
        snackBarTop: true,  // Snackbar position top
      },

      phoneNumbers: [""],
      userTypes: [], 
      //provinces: ['Province 1', 'Province 2'], // Example provinces
      //districts: ['District 1', 'District 2'], // Example districts
      //dsds: ['DSD 1', 'DSD 2'], // Example DSDs
      gnds: ['GND 1', 'GND 2'], // Example GNDs
      gender: ['Male', 'Female'],

      
      mDistrictLoading: false,
      mProvinceLoading:false,
      mDSDLoading: false,
      mGNDLoading: false,
      provinces: [],
      districts: [],
      dsdList: [],
      gndList: [],
    };
  },
  mounted() {
    this.getAllProvinces();
    this.getAllUserRoles();
    this.getDashboardPermissions();
  },
  methods: {

    handleSwitchChange(item) {
      if (!item.isOn) {
        // Reset the permissions when the switch is turned off
        item.permissions.read = false;
        item.permissions.write = false;
        item.permissions.delete = false;
      }
    },

    handlePermissionChange(item, permissionType) {
      // You can perform additional logic here if needed
      // For example, you can ensure that if the 'delete' permission is enabled, 'read' and 'write' must also be enabled.
      if (permissionType === 'delete' && item.permissions.delete) {
        item.permissions.read = true;
        item.permissions.write = true;
      }

      // If both 'read' and 'write' are disabled, ensure 'delete' is also disabled
      if (!item.permissions.read && !item.permissions.write) {
        item.permissions.delete = false;
      }
    },

    // Get Dashboard
    getDashboardPermissions() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`/api/getDashboard?userId=${userId}`, 
        {}, 
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Authorization header with the Bearer token
          }
        })
        .then(response => {
          const dashboards = response.data.data;
          this.permissionsItems = dashboards.map(dashboard => ({
            id: dashboard.id,
            premission_dashboard: dashboard.premission_dashboard,
            isOn: false,
            permissions: { read: false, write: false, delete: false },
          }));
          console.log('Dashboard permissions:', this.permissionsItems);
        })
        .catch(error => {
          console.error('Error fetching dashboard permissions:', error);
        });
    },

    // Open DashBoard Seletec dialog
    openDashBoardSeletDialog(selectedId){

      // console.log('diuahuid',selectedId);

      const selectedItem = this.userTypes.find(type => type.id === selectedId);
      if (selectedItem) {
        this.selectedRoleId =  selectedItem.id
        this.selectedRoleName =  selectedItem.name
        console.log('Selected ID:', selectedItem.id);
        console.log('Selected Name:', selectedItem.name);
        this.dashboardSelectDialog = true;
      }
      // this.dashboardSelectDialog = true;
    },

    getAllUserRoles() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token'); 

      axios.post('/api/getUserRolesType', {
          userId: userId
      }, {
          headers: {
              'Authorization': `Bearer ${token}`
          }
      })
      .then(response => {
          let usrRole = response.data.data;
          this.userTypes = usrRole.map(item => {
              return {
                  id: item.id,
                  name: item.role_type
              };
          });
          console.log('User role types:', this.userTypes);
      })
      .catch(error => {
          console.error('Error fetching user roles:', error);
      });
    },
    getAllProvinces() {
      this.districts = [];
      this.dsdList = [];
      this.gndList = [];
      this.mProvinceLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.provinces = response.data.data;
              this.mProvinceLoading = false;
            }
          })
          .catch(() => {
            this.mProvinceLoading = false;
          });
    },

    getAllDistrictAccProv(province) {
      console.log('Province selected:', province);
      this.districts = [];
      this.mDistrictLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const headers = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
          .then(response => {
            console.log('District API response:', response.data);
            if (response.data.http_status === 'success') {
              this.districts = response.data.data;
              console.log('District API response:', response.data);
              this.mDistrictLoading = false;

            }
          })
          .catch(error => {
            this.mDistrictLoading = false;
            console.error('Error fetching districts:', error);

          });
    },

    getAllDsd(district) {
      this.dsdList = [];
      this.mDSDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.dsdList = response.data.data;
              this.mDSDLoading = false;
            }
          })
          .catch(() => {
            this.mDSDLoading = false;
          });
    },

    getAllGnd(dsd) {
      this.gndList = [];
      this.mGNDLoading = true;
      // let laravel = JSON.parse(window.Laravel);
      const header = {
        // 'X-CSRF-TOKEN': laravel.csrfToken,
        'X-Requested-With': 'XMLHttpRequest',
        'content-type': 'multipart/form-data',
      };

      axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
          .then(response => {
            if (response.data.http_status === 'success') {
              this.gndList = response.data.data;
              this.mGNDLoading = false;
            }
          })
          .catch(() => {
            this.mGNDLoading = false;
          });
    },

    
    backPageRoute(){
      this.$router.back();
    },

    addPhoneNo() {
        this.phoneNumbers.push('');
    },

    removePhoneNo(index) {
        this.phoneNumbers.splice(index, 1);
    },

    // Method to convert a file to a base64 string
    convertToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const result = reader.result;
          // Remove the "data:image/png;base64," part
          const base64String = result.split(',')[1];
          resolve(base64String);
        };
        reader.onerror = error => reject(error);
      });
    },


    async registerUser() {
      try {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");

        console.log("Token:", token);
        console.log("Retrieved UserId from localStorage:", userId);

        // Log user data and form details
        console.log("User Data:", this.user);
        console.log("Phone Numbers:", this.phoneNumbers);

        // Form validation
        if (this.$refs.form && this.$refs.form.validate()) {
          // Validate password and confirmation password

          // console.log('user Regi',this.user.password === this.user.confirmPassword);
          // if (this.user.password !== this.user.confirmPassword) {
          //   this.showSnackbar("Passwords do not match.", "warning");
          //   return;
          // }

          const formData = new FormData();
          formData.append("userId", userId);
          console.log("userId", userId);
          formData.append("fullName", this.user.fullName);
          console.log("fullName", this.user.fullName);
          formData.append("userName", this.user.userName);
          console.log("userName", this.user.userName);
          formData.append("userType", this.selectedRoleName);
          console.log("userType", this.selectedRoleName);
          formData.append("userRoleId", this.selectedRoleId);
          console.log("userRoleId", this.selectedRoleId);
          // formData.append("geofence", this.user.geofence);
          formData.append("emailAddress", this.user.emailAddress);
          console.log("emailAddress", this.user.emailAddress);
          formData.append("nic", this.user.nic);
          console.log("nic", this.user.nic);
          formData.append("gender", this.user.gender);
          console.log("gender", this.user.gender);
          formData.append("province", this.user.province);
          console.log("province", this.user.province);
          formData.append("district", this.user.district);
          console.log("district", this.user.district);
          formData.append("dsd", this.user.dsd);
          console.log("dsd", this.user.dsd);
          formData.append("gnd", this.user.gnd);
          console.log("gnd", this.user.gnd);
          formData.append("address", this.user.address);
          console.log("address", this.user.address);

          formData.append("routePlane", this.user.geofence.includes("Route Plan Geofence") ? 1 : 0);
          formData.append("myRoute", this.user.geofence.includes("My Route Geofence") ? 1 : 0);
          formData.append("collection", this.user.geofence.includes("Collection Geofence") ? 1 : 0);
          formData.append("sales", this.user.geofence.includes("Sales Geofence") ? 1 : 0);
          
          formData.append("mobileNumber", this.phoneNumbers);
          console.log("mobileNumber", this.phoneNumbers);

          const dashboards = this.permissionsItems
          .filter(item => item.isOn) // Only include dashboards where the switch is ON
          .map(item => ({
            dashboardId: item.id, // Assuming item.id represents the dashboard ID
            readPremission: item.permissions.read,
            writePremission: item.permissions.write,
            deletePremission: item.permissions.delete,
          }));

          const payload = {
            userRoleId: this.selectedRoleId, 
            accessDashboard: dashboards, 
            userId, 
          };


          const jsonPayload = JSON.stringify(payload);

          console.log('fuckingdata',jsonPayload);
          formData.append("accessDashboard", jsonPayload);
          
          // console.log('phone numbers array',JSON.stringify(this.phoneNumbers));

          // // Append images to form data
          // if (this.user.image) {
          //   this.user.image.forEach((image, index) => {
          //     formData.append(`image_${index}`, image);
          //   });
          // }

          // Append the single image to form data
          // if (this.user.image && this.user.image.length > 0) {
          //   formData.append("image", this.user.image[0]);
          // }

          // console.log("image", this.user.image[0]);

          // Append the single image to form data as base64
          // Convert the image to a base64 string if an image is selected
        if (this.user.image && this.user.image.length > 0) {
          await this.convertToBase64(this.user.image[0]).then(base64String => {
            formData.append("image", base64String);
        console.log("image", base64String);
          });
        }



          // API call
          const response = await axios.post(
            "/api/createUser",
            formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,              
              },
            }
          );

          // Log the response from the server
          console.log("API Response:", response);
          console.log("API Response2:", response.data);
          console.log("API Response3:", response.data.data);

          if (response.status === 201) {
            this.showSnackbar("User registered successfully!", "success");
            this.cancel(); // Reset form after successful registration
          } else {
            this.showSnackbar("Unexpected response status.", "warning");
          }
        }
      } catch (error) {
        if (error.response) {
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request data:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
        this.showSnackbar("Failed to register user.", "error");
      }
    },


    cancel() {
      // Reset form fields
      this.user = {
        fullName: "",
        userName: "",
        password: "",
        confirmPassword: "",
        userType: "",
        emailAddress: "",
        nic: "",
        gender: "",
        province: "",
        district: "",
        dsd: "",
        gnd: "",
        address: "",
        image: [],
        phoneNo:"",
      };
      this.phoneNumbers = [];
      this.previewImages = [];
      // this.permissionsItems = [];
    },
    
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.handleFiles(file);
      }
    },

    onDrop(event) {
      const file = event.dataTransfer.files[0];
      if (file) {
        this.handleFiles(file);
      }
    },

    onDragOver(event) {
      event.preventDefault();
    },

   

    handleFiles(file) {
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

      if (!validImageTypes.includes(file.type)) {
        alert('Only image files are allowed!');
        return;
      }

      // Clear previous image
      this.user.image = [];
      this.previewImages = [];

      this.user.image.push(file);
      this.previewImages.push(URL.createObjectURL(file));
    },

    removeImage(index) {
      if (index >= 0 && index < this.user.image.length) {
        this.user.image.splice(index, 1);
        this.previewImages.splice(index, 1);
      }
    },

    createUserRole(){
      this.$router.push({ path: '/create-user-role' });
    },

  }
};
</script>
    
<style scoped>
  .page-title {
    font-weight: bold;
  }

  .light-gray-text{
    color: #a09e9e !important;
  }

  .red-text {
    color: #b00d0d;
  }
  
  .gray-text {
    color: #757575 !important;
  }

  .btn-border{
    border-block-end-color: #9a1313;
  }

  .card-size{
    width: 100%;
    padding: 0;
    margin-bottom: 0;
  }

  .tool-bar-style {
    display: flex;
    justify-content: end;
  }

  .divider-vertical{
    color: #3c3b3b !important;
  }

  .card-two-title{
    color: black;
  }
  .leads-details-card{
    height: 100%;
  }
  .card-hight-fixed{
    height: 100%;
    overflow: auto;
    max-height: 63vh;
  }
  .red-background-text {
    background-color: #f0bcbc;
    color: #BB0000;
  } 
  .gray-background-text {
    background-color: rgb(202, 202, 202);
    color: black;
  } 
  .divider-two-style{
    margin-top: -5px;
  }
  .remove-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: white;
    border-radius: 50%; /* This will make the background round */
    padding: 4px; /* Adjust as needed to control the icon size */
  }

  .remove-icon .v-icon {
    color: red;
  }

  .placeholder-container {
    width: 100%;
    height: 120px;
    text-align: center;
    color: #9a1313;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .image-preview {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .image-card {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
  }
  .image-card-container {
    margin: 0 8px 8px 0;
  }
  .hidden-file-input {
    display: none;
  }
  .drop-area {
    border: 2px dashed #9a1313;
    padding: 20px;
    margin-top: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
  }

    </style>
    