<!-- Dashboard Component three - Line Chart -->

<template>
    <v-row class="mx-3 mt-1">
        <v-col cols="4">
            <v-select
                label="Year"
                placeholder="Select Year"
                variant="outlined"
            ></v-select>
        </v-col>
      <v-col cols="2">
        <v-card class="pa-2 flat">
          <h6 class="text-success">{{ collectionsTarget }}</h6>
          <h6 class="gray-text font-weight-bold">
            <v-icon class="box-icon-color-1" size="10">mdi-checkbox-blank-outline</v-icon>
            Collections &nbsp; [Target]
          </h6>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="pa-2 flat">
          <h6 class="yellow-text">{{ salesTarget }}</h6>
          <h6 class="gray-text font-weight-bold">
            <v-icon class="box-icon-color-2" size="10">mdi-checkbox-blank-outline</v-icon>
            Sales &nbsp; [Target]
          </h6>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="pa-2 flat">
          <h6 class="text-primary">{{ collectionsActual }}</h6>
          <h6 class="gray-text font-weight-bold">
            <v-icon class="box-icon-color-3" size="10">mdi-checkbox-blank-outline</v-icon>
            Collections &nbsp; [Actual]
          </h6>
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-card class="pa-2 flat">
          <h6 class="red-text">{{ salesActual }}</h6>
          <h6 class="gray-text font-weight-bold">
            <v-icon class="box-icon-color-4" size="10">mdi-checkbox-blank-outline</v-icon>
            Sales &nbsp; [Actual]
          </h6>
        </v-card>
      </v-col>

    </v-row>
    <v-card flat>
        <v-card-title class="width-of-card">
          <div ref="chartdiv" style="width: 100%; height: 400px;"></div>
        </v-card-title>
    </v-card>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import axios from 'axios';

am4core.useTheme(am4themes_animated);

export default {
  name: 'LineChartDashboardComponentThree',

  data() {
    return {
      collectionsTarget: 0,
      salesTarget: 0,
      collectionsActual: 0,
      salesActual: 0
    };
  },

  mounted() {
    let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);
    chart.paddingRight = 20;

    const token = localStorage.getItem("token");

    // Fetch data from the backend
    axios.post('/api/getChartData', {}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
        .then(response => {
          const data = response.data;
          console.log('chartttt',data);

          let totalCollectionsActual = data.total_collection_amount / 1000000;
          let totalSalesActual = data.total_sales_amount / 1000000;

          this.collectionsActual = totalCollectionsActual.toFixed(1) + " M";
          this.salesActual = totalSalesActual.toFixed(1) + " M";
          this.collectionsTarget = (totalCollectionsActual * 1.1).toFixed(1) + " M";
          this.salesTarget = (totalSalesActual * 1.1).toFixed(1) + " M";


          const salesData = data.sales.map(entry => ({
            date: new Date(entry.created_date),
            sales_actual: parseFloat(entry.sales_amount || 0) / 1000000,
            sales_target: parseFloat(entry.sales_amount || 0) * 1.1 / 1000000,
            collections_actual: 0,
            collections_target: 0
          }));

          const collectionsData = data.collections.map(entry => ({
            date: new Date(entry.created_date),
            collections_actual: parseFloat(entry.paid_amount || 0) / 1000000,
            collections_target: parseFloat(entry.paid_amount || 0) * 1.1 / 1000000,
            sales_actual: 0,
            sales_target: 0
          }));


          chart.data = [...salesData, ...collectionsData];
        })
        .catch(error => console.error("Error fetching data:", error));


    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.dateFormats.setKey("month", "MMM");
    dateAxis.periodChangeDateFormats.setKey("month", "MMM");
    dateAxis.renderer.cellStartLocation = 0.2;
    dateAxis.renderer.cellEndLocation = 0.8;
    dateAxis.baseInterval = { timeUnit: "month", count: 1 };
    dateAxis.gridIntervals.setAll([{ timeUnit: "month", count: 1 }]);


    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 5;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minGridDistance = 20;
    valueAxis.renderer.opposite = true;
    valueAxis.renderer.labels.template.adapter.add("text", function(text) {
      return text + " M";
    });
    valueAxis.renderer.labels.template.fontSize = 14;


    let salesActualSeries = chart.series.push(new am4charts.ColumnSeries());
    salesActualSeries.dataFields.dateX = "date";
    salesActualSeries.dataFields.valueY = "sales_actual";
    salesActualSeries.tooltipText = "Actual Sales: {valueY.value}M";
    salesActualSeries.name = "Actual Sales";
    salesActualSeries.columns.template.fill = am4core.color("#b00d0d");
    salesActualSeries.columns.template.stroke = am4core.color("#b00d0d");
    salesActualSeries.columns.template.width = am4core.percent(30);


    let collectionsActualSeries = chart.series.push(new am4charts.ColumnSeries());
    collectionsActualSeries.dataFields.dateX = "date";
    collectionsActualSeries.dataFields.valueY = "collections_actual";
    collectionsActualSeries.tooltipText = "Actual Collections: {valueY.value}M";
    collectionsActualSeries.name = "Actual Collections";
    collectionsActualSeries.columns.template.fill = am4core.color("#0D47A1");
    collectionsActualSeries.columns.template.stroke = am4core.color("#0D47A1");
    collectionsActualSeries.columns.template.width = am4core.percent(30);


    let salesTargetSeries = chart.series.push(new am4charts.LineSeries());
    salesTargetSeries.dataFields.dateX = "date";
    salesTargetSeries.dataFields.valueY = "sales_target";
    salesTargetSeries.tooltipText = "Target Sales: {valueY.value}M";
    salesTargetSeries.name = "Target Sales";
    salesTargetSeries.strokeWidth = 2;
    salesTargetSeries.stroke = am4core.color("#efb907");


    let collectionsTargetSeries = chart.series.push(new am4charts.LineSeries());
    collectionsTargetSeries.dataFields.dateX = "date";
    collectionsTargetSeries.dataFields.valueY = "collections_target";
    collectionsTargetSeries.tooltipText = "Target Collections: {valueY.value}M";
    collectionsTargetSeries.name = "Target Collections";
    collectionsTargetSeries.strokeWidth = 2;
    collectionsTargetSeries.stroke = am4core.color("rgb(27, 185, 25)");


    chart.cursor = new am4charts.XYCursor();
    this.chart = chart;

    chart.logo.disabled = true;
  },

  beforeUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};

</script>


<style scoped>
.hello {
  width: 100%;
  height: 400px;
}
.width-of-card{
  width: 100%;
}
.red-text {
  color: #b00d0d;
}

.gray-text {
  color: #757575 !important;
}

.box-icon-color-1{
  background-color: rgb(27, 185, 25);
  color: rgb(27, 185, 25);
}

.yellow-text{
  color: #efb907 !important;
}

.box-icon-color-2{
  background-color: #efb907;
  color: #efb907;
}

.box-icon-color-3{
  background-color: blue;
  color: blue;
}

.box-icon-color-4{
  background-color: #b00d0d;
  color: #b00d0d;
}
</style>