<!-- Top selling products - main dashboard second component -->

<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pa-3">
        <v-card-title class="card-h4-title">
          TOP SELLING PRODUCTS
          <div class="button-style">
            <v-btn :value="'weekly'" color="error" dark variant="elevated">Weekly</v-btn>
            <v-btn :value="'monthly'" class="gray-background-text" variant="elevated">Monthly</v-btn>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
              <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </div>
        </v-card-title>

        <v-divider class="mt-3"></v-divider>

        <!-- Top selling products data table -->
        <v-col cols="12">
          <v-data-table
              :headers="headers"
              :items="topSellingProductsList"
              class="data-table gray-text"
              item-value="name"
              items-per-page="5"
          >

            <!-- Product Name Column -->
            <template v-slot:[`item.products`]="{ item }">
              <div class="products-column">
                <div v-for="(product, index) in item.products" :key="index" class="d-flex align-center">
                  <v-img
                      v-if="product.product.product_image && product.product.product_image.length > 0"
                      :src="`http://sfa.backend.celata.org/SFA/public/${product.product.product_image[0].image_url}`"
                      class="product-image mr-3"
                      max-height="50"
                      max-width="50"
                  ></v-img>
                  <span>{{ product.product_name }}</span>
                </div>
              </div>
            </template>

            <!-- Price Column -->
            <template v-slot:[`item.products.unit_price`]="{ item }">
              <div class="price-column">
                <div v-for="(product, index) in item.products" :key="index">
                  <div>{{ product.unit_price }}</div>
                  <v-divider class="my-1"></v-divider>
                </div>
              </div>
            </template>

            <!-- Quantity Sold Column -->
            <template v-slot:[`item.quantity_sold`]="{ item }">
              <div class="quantity-column">
                <span>{{ item.quantity_sold }}</span>
              </div>
            </template>

            <!-- Sales Amount Column -->
            <template v-slot:[`item.sales_amount`]="{ item }">
              <div class="amount-column">
                <span>{{ item.sales_amount }}</span>
              </div>
            </template>

            <!-- Outlets Name Column -->
            <template v-slot:[`item.store_name`]="{ item }">
              <div class="outlet-column">
                <span>{{ item.store_name }}</span>
              </div>
            </template>

            <!-- Assigned Agent Column -->
            <template v-slot:[`item.user_type`]="{ item }">
              <div class="agent-column">
                <span>{{ item.user_type }}</span>
                <div v-if="item.agent_id" class="red-text">ID: {{ item.agent_id }}</div>
              </div>
            </template>

          </v-data-table>
        </v-col>
      </v-card>


    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios';
import 'jspdf-autotable';
import * as XLSX from "xlsx";
export default {
  name: 'TopSellingProducts',
  data() {
    return {
      
      weeklyMenu: false,
      weeklyDate: null,
      monthlyMenu: false,
      monthlyDate: null,
      topSellingHeaders: [
        { text: 'PRODUCT NAME', value: 'order_id', title: 'PRODUCT NAME' },
        { text: 'PRICE', value: 'sales_amount', title: 'PRICE' },
        { text: 'QUANTITY', value: 'quantity_sold', title: 'QUANTITY' },
        { text: 'AMOUNT', value: 'paid_amount', title: 'AMOUNT' },
        { text: 'OUTLETS NAME', value: 'store_name', title: 'OUTLETS NAME' },
        { text: 'ASSIGNED AGENT', value: 'user_type', title: 'ASSIGNED NAME' },
      ],
      topSellingProductsList: [],
      headers: [
        { text: 'PRODUCT NAME', value: 'products', title: 'PRODUCT NAME' },
        { text: 'PRICE', value: 'products.unit_price', title: 'PRICE' },
        { text: 'QUANTITY', value: 'quantity_sold', title: 'QUANTITY' },
        { text: 'AMOUNT', value: 'sales_amount', title: 'AMOUNT' },
        { text: 'OUTLETS NAME', value: 'store_name', title: 'OUTLETS NAME' },
        { text: 'ASSIGNED AGENT', value: 'user_type', title: 'ASSIGNED AGENT' },
      ],

      items: [
        {
          image: 'top-selling-oroducts-1.jpg',
          name: 'Burger Press Non-Stick Hamburger',
          price: 'LKR. 1,630.00',
          quantity: '02 - QTY',
          amount: 'LKR. 3,260.00',
          outletsName: [
            { name: 'Liyanage Enterprises', unit: 'UNIT - 01' },
            { name: 'Gamage Stores', unit: 'UNIT - 01' }
          ],
          assignedAgent: [
            { name: 'Mr. Cherith Maitreya', regNum: 'Reg num: 131' },
            { name: 'Mr. Cherith Maitreya', regNum: 'Reg num: 2311' }
          ],
        },
        {
          image: 'top-selling-oroducts-2.jpg',
          name: '10pcs Rectangular Tinfoil Tray',
          price: 'LKR. 720.00',
          quantity: '04 - QTY',
          amount: 'LKR. 2,880.00',
          outletsName: [
            { name: 'Jayasundara Stores', unit: 'UNIT - 04' }
          ],
          assignedAgent: [
            { name: 'Mr. Kumarasiri Aponsu', regNum: 'Reg num: 0784' }
          ],
        },
        {
          image: 'top-selling-oroducts-3.jpg',
          name: 'Food Flipping Steak Clips Stainless Steel',
          price: 'LKR. 1,120.00',
          quantity: '03 - QTY',
          amount: 'LKR. 3,360.00',
          outletsName: [
            { name: 'Gamage Stores', unit: 'UNIT - 03' },
            { name: 'Naveen Groceries', unit: 'UNIT - 01' }
          ],
          assignedAgent: [
            { name: 'Mr. Supun Kanakaratne', regNum: 'Reg num: 2024' },
            { name: 'Mr. Mahendra Perera', regNum: 'Reg num: 3561' }
          ],
        },
        {
          image: 'top-selling-oroducts-4.jpg',
          name: 'Egg Frying Pan Nonstick',
          price: 'LKR. 3,240.00',
          quantity: '02 - QTY',
          amount: 'LKR. 6,480.00',
          outletsName: [
            { name: 'Naveen Groceries', unit: 'UNIT - 01' },
            { name: 'Naveen Groceries', unit: 'UNIT - 02' }
          ],
          assignedAgent: [
            { name: 'Mr. Mahendra Perera', regNum: 'Reg num: 3561' }
          ],
        },
      ],
    };
  },
  created() {
    this.getTopSellingProduct();
  },
  methods:{

    getTopSellingProduct(){

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
     
      axios.post(`/api/sales/all/view`,
          { userId: userId,
          },
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })

          .then(response => {
          
            this.topSellingProductsList = response.data.data;
            console.log('topsellingProduct:', this.topSellingProductsList);
           
          })
          .catch(error => {
            console.error('Error fetching sub categories:', error);
          });

    },
    exportExcel() {
        const tableData = this.items.map((item, index) => ({
          '#': index + 1,
          'PRODUCT NAME': item.name,
          'PRICE': item.price,
          'QUANTITY': item.quantity,
          'AMOUNT': item.amount,
          // 'OUTLETS NAME': item.outlets,
          // 'ASSIGNED AGENT': item.agent,
          'OUTLETS NAME': item.outletsName
            .map((outlet, i) => `${i + 1}. ${outlet.name} (${outlet.unit})`)
            .join('\n'), // Joining with new line to make each outlet appear on a separate line
          'ASSIGNED AGENT': item.assignedAgent
            .map(agent => `${agent.name} (${agent.regNum})`)
            .join('\n'), // Joining with new line to make each agent appear on a separate line
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Top Selling Products');


        XLSX.writeFile(workbook, 'top_selling_products_analysis.xlsx');
      },
  }
};
</script>

<style scoped>
.card-h4-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #757575;
  font-size: 1rem;
}
.button-style {
  display: flex;
  justify-content: space-between;
}
.gray-text {
  color: #757575 !important;
}
.red-text {
  color: #BB0000;
}

.gray-background-text {
  background-color: #BDBDBD;
}
.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}
.data-table {
  width: 100%;
  height: 100%;
  border: black;
}

.product-column {
  display: flex;
}

.product-image {
  width: 70px;
  height: 70px;
  margin-left: 10px;
  padding: 4px;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  border-width: 4px;
  box-shadow: #757575;
  background-color: white;
  margin: 10px;
}

.product-title {
  white-space: pre-wrap;
  display: flex;
}

.outlet-column,
.agent-column {
  display: flex;
  flex-direction: column;
}

.outlet-secondary,
.agent-secondary {
  color: red;
}

</style>
