<template>
  <v-container fluid>
    <v-row class="main-heading">
      <v-col cols="12" md="11">
        <v-row>
          <v-card-title>OVERALL HISTORY</v-card-title>
        </v-row>
        <v-row>
          <v-card-subtitle>Analyse the collection of overall history tracking data based on agents.</v-card-subtitle>
        </v-row>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn class="back-button">BACK</v-btn>
      </v-col>
    </v-row>

    <v-row class="sub-heading">
      <v-card class="sub-heading-card d-flex align-center" variant="flat">
        <v-col cols="12" md="4">
          <v-card-subtitle class="his-sub-heading">HISTORY</v-card-subtitle>
        </v-col>

        <v-row  class="card-two-title" justify="end"  >

          <v-btn class="red-light-text" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </v-row>
      </v-card>
    </v-row>

    <v-card class="main-card ">
      <v-row class="text-filed-row">
        <v-col cols="12" md="4">
          <v-date-input
              v-model="dates"
              clearable
              label="Date"
              max-width="368"
              multiple="range"
              placeholder="select date range"
              variant="outlined"
          ></v-date-input>
        </v-col>
        <v-col cols="12" md="4">
          <v-select
              v-model="agentList"
              :items="agentDataOptions"
              clearable
              item-title="name"
              item-value="name"
              label="Agent"
              max-width="368"
              multiple
              placeholder="select agent name"
              variant="outlined"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
              v-model="serachModel"
              class="text-black"
              label="Search"
              persistent-placeholder
              placeholder="Search"
              variant="outlined"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row class="table-row">
        <v-col class="table-col" cols="12">
          <v-data-table
              :headers="headers"
              :items="filteredData"
              :search="serachModel"
              class="data-table"

          >
          <template v-slot:[`item.agentName`] ="{ item }">
            <v-row class="custom-row">
              <p>{{ item.agentName }}</p>
              <p class="register-number">{{ item.RegisterNumber }}</p>
            </v-row>
          </template>

          <template v-slot:[`item.fistAddress`] ="{ item }">
            <v-row class="custom-row">
              <p>{{ item.fistAddress }}</p>
              <p class="register-number">{{ item.statTime }}</p>
            </v-row>
          </template>

          <template v-slot:[`item.lastAddress`] ="{ item }">
            <v-row class="custom-row">
              <p>{{ item.lastAddress }}</p>
              <p class="register-number">{{ item.endTime }}</p>
            </v-row>
          </template>


            <template v-slot:[`item.actions`]="{ item }" >
              <v-row>
                <v-col  cols="5">
                  <v-btn
                      class="mr-2 bg-blue-lighten-4"
                      color="blue"
                      icon
                      rounded="lg"
                      size="small"
                      variant="outlined"
                      width="210"
                      @click="mapView(item)"
                  >
                    <v-icon class="mr-2">mdi-check-circle</v-icon>MAP VIEW
                  </v-btn>
                </v-col>
                <v-col class=" ml-0" cols="4">
                  <v-btn
                      class="bg-red-lighten-4"
                      color="red"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>

          
          <!-- Delete Confirmation Dialog -->
          <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
            <v-card class="pa-1">
              <v-row>
                <v-col cols="12" md="10">
                  <v-card-title class="headline">DELETE TRACKING DATA</v-card-title>
                </v-col>
                <v-col class="d-flex justify-end" cols="12" md="2">
                  <v-btn class="mt-3" size="x-small" @click="deleteDialog = false">
                    <v-icon>mdi-close-circle</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this tracking record ?</v-card-text>
              <v-card-text class="font-weight-medium">
                This tracking record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
                <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-col>
      </v-row>

      <!--  collection recode edit view component call to dialog box-->
      <v-dialog v-model="mapViewDialog" class="solid-dialog" fullscreen persistent>
        <v-card>
          <v-row class="first-row-dialog" >
            <v-col class="d-flex justify-end" cols="12" md="2">
              <v-toolbar-title class="mt-1 ml-6" >
                <img  :src="require('@/assets/app_bar_logo.png')" alt=""/>
              </v-toolbar-title>
            </v-col>
            <v-col class="d-flex justify-end" cols="12" md="10">
              <v-btn class="closeC bg-red mr-2 justify-center font-weight-thin mt-3 mr-3" color="white" height="30" icon rounded="lg" variant="text" width="90" @click="closeD" >
                <v-icon>mdi-close-circle-outline</v-icon> CLOSE
              </v-btn>
            </v-col>
            <v-row >
              <v-col class="fc" cols="12" >
                <v-card>
                <v-icon>mdi-view-dashboard
                </v-icon>
                <v-label class="ml-5 ">MAP VIEW</v-label>
                </v-card>
              </v-col >
            </v-row>
          </v-row>

            <v-row ref="content">
              <v-col cols="12">
                <v-card  style="height: 180px; margin-top: 50px; margin-bottom: 10px; margin-left:20px; width: 100%; ">
                  <v-row>
                    <v-col class="d-flex justify-center align-center" cols="12" md="3">
                  <v-img
                      :src="OfficerDetails.user_image ? `${baseUrl}/SFA/public/${user.OfficerDetails.user_image}` : require('@/assets/ASSETS/PERSONS/person-3.jpg')" 
                      class="rounded-circle"
                      max-width="150"
                  ></v-img>
                </v-col>
                    <v-col cols="12" md="3">
                        <v-col class="mt-6 mb-3">
                          <div>Agent : {{ this.agentName  }}</div>
                          <v-divider></v-divider>
                          <div>Date : {{ this.visitedDate }}</div>
                          <v-divider></v-divider>
                          <div>Total distance : {{ this.totalDistance  }} KM</div>
                          <v-divider></v-divider>
                          <div>Start time : {{  this.startTime  }}</div>
                          <v-divider></v-divider>
                          <div>End time : {{  this.endTime  }}</div>
                          <v-divider></v-divider>
                        </v-col>
                    </v-col>


                  <!-- PDF Download Button -->
                  <v-col class="d-flex justify-end align-center" cols="12" md="5">
                    <v-btn class="red-light-text" variant="outlined"  @click="downloadPdf">
                      <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;DownLoad PDF
                    </v-btn>
                    <!-- <v-btn
                      class="mr-5 bg-red-lighten-4"
                      icon
                      rounded="lg"
                      size="small"
                      style="margin-top: 30vh; margin-left: 10vw;"
                      @click="exportPDF"
                    >
                      <v-icon class="mr-2 bg-red">mdi-file-pdf</v-icon> Download PDF
                    </v-btn> -->
                  </v-col>

                  </v-row>
                </v-card>
              </v-col>
            </v-row>



            <v-row class="mt-0">
              <!--history map view-->
              <v-card class="pa-5" width="100%" >
                <v-row>
                <v-col cols="8">
                <GMapMap
                    :center="center"
                    :zoom="6.5"
                    style="height: 500px; width: 100%;"
                >
                  <GMapPolyline :options="polylineOptions" :path="pathCoordinates"  />

                  <!-- Start Point Marker -->
                  <GMapMarker
                      :icon="startIcon"
                      :position="startPoint"
                      :title="'Start Point'"
                  />

                  <!-- End Point Marker -->
                  <GMapMarker
                      :icon="endIcon"
                      :position="endPoint"
                      :title="'End Point'"
                  />
                </GMapMap>
                </v-col>
                <v-col cols="4">
                  <v-card class="dataCard" flat style="height: auto; width: 100%;">
                    <v-card-text>
                      <!-- Agent Name -->
                      <div class="font-weight-bold mb-4" style="font-size: 14px;">
                        Agent Name: {{ OfficerDetails.name }}
                      </div>

                      <!-- Timeline -->
                      <v-timeline align="start" density="compact">
                        <!-- First Outlet Timeline Item -->
                        <v-timeline-item class="mt-0" dot-color="red" size="x-small">
                          <v-row>
                            <v-col cols="1">
                              <v-icon color="red">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11">
                              <v-card-subtitle class="mb-1" style="font-size: 12px;">
                                {{ startLocation }}
                              </v-card-subtitle>
                              <v-card-subtitle class="mb-1" style="font-size: 12px;">
                                {{ firstOutlet.created_at }} | <v-chip size="small" style="background-color: blue ; color: white">Start Location</v-chip>
                              </v-card-subtitle>
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                        </v-timeline-item>

                        <!-- Second Outlet Timeline Item -->
                        <v-timeline-item class="mt-0" dot-color="red" size="x-small">
                          <v-row>
                            <v-col cols="1">
                              <v-icon color="red">mdi-check-circle-outline</v-icon>
                            </v-col>
                            <v-col cols="11">
                              <v-card-subtitle class="mb-1" style="font-size: 12px;">
                                {{ endLocation }}
                              </v-card-subtitle>
                              <v-card-subtitle class="mb-1" style="font-size: 12px;">
                                {{ secondOutlet.created_at }} | <v-chip size="small" style="background-color: #057b0a; color: white">End Location</v-chip>
                              </v-card-subtitle>
                              <v-divider></v-divider>
                            </v-col>
                          </v-row>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>
                  </v-card>

                  <v-card>

                  </v-card>

                </v-col>

                </v-row>
              </v-card>
            </v-row>
        </v-card>

      </v-dialog>
    </v-card>

  </v-container>
</template>

<script>

// import { Map, MapMarker, MapPolyline  } from '@fawmi/vue-google-maps';
import index from "vuex";
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2pdf from 'html2pdf.js';
import * as XLSX from "xlsx";



export default {

  components: {
    // Map, MapPolyline, MapMarker
  },
  data() {
    return {
      startLocation:"",
      endLocation:"",
      deleteAgentId:"",
      deleteDate:"",
      startTime:"",
      endTime:"",
      visitedDate:"",
      agentName:"",
      agentDataOptions:[],
      dates: [],
      serachModel:"",
      firstOutlet:"",
      secondOutlet:"",
      baseUrl:"",
      search: ' ',
      deleteDialog: false,
      itemToDelete: null,
      deletedDate:"",
      deletedType:"",
      routeId:"",
      mapViewDialog: false,

      headers: [
        {text: 'AGENT NAME', value: 'agentName', title: 'AGENT NAME',},
        {text: 'START LOCATION', value: 'fistAddress', title: 'START LOCATION',},
        {text: 'END LOCATION', value: 'lastAddress', title: 'END LOCATION',},
        {text: 'DATE', value: 'date', title: 'DATE'},
        {text: 'Action', value: 'actions', sortable: false, title: 'ACTION' }
      ],
      trackingData:[],
      totalDistance:"",
      trackingHistary:[],
      OfficerDetails:"",

      center: { lat: 7.375783804167988, lng: 80.63752981355924 },
      pathCoordinates: [
        { lat: "", lng: "" },
      ],
      polylineOptions: {
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 3,
      },
      startPoint:"",
      endPoint:"",
      startIcon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
      },
      endIcon: {
        url: 'https://maps.google.com/mapfiles/ms/icons/red-dot.png',
      },

      agentList:[],

      agents: [
        {
          location: 'Mawaramandiya',
          lastUpdated: '30 seconds ago',
          locAddress: 'bla blaa blaaaaa',
          locationType: 'startLocation',
          trips: [
            { id: 1, destination: 'Keells - Edmonton', time: '09:16 AM' },
            { id: 2, destination: 'Keells - Kohuwala', time: '10:30 AM' },
          ],
        },
      ],

      mapCenter: { lat: 6.023129913557325, lng: 80.79783798218944 },

    };
  },
  computed: {

    index() {
      return index

    },

    filteredData() {
     
      let filtered = this.trackingData;

            filtered = filtered.filter(item =>
            item.agentName.toLowerCase().includes(this.serachModel.toLowerCase()) 
            );

            console.log('Filtered Data111:', filtered);

            return filtered;

      // return this.trackingData.filter((item) => {
      //   //
      //   return matchesSearchModel && withinDateRange && matchesOfficer;
      // });
    },
  },

  mounted(){
    this.getAllTracking();
    this.fetchAllAgents();
    this.getBaseUrl();
  },

  methods: {

    getBaseUrl(){
      this.baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
    },

    downloadPdf() {
      const element = this.$refs.content;
      const options = {
        margin: 1,
        filename: 'webpage.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      };
      html2pdf().from(element).set(options).save();
    },

    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('OverRoll Tracking report', 14, 10);

      // Prepare the data for the table
      const tableData = this.trackingData.map((item, index) => [
        index + 1,
        item.agentName,
        item.RegisterNumber,
        item.date,
        item.fistAddress,
        item.lastAddress,
      ]);

      // console.log('pdfdata111', tableData);

      // Define the columns for the table
      const tableColumns = ['#', 'AGENT NAME', 'REGISTER NUMBER', 'VISITED DATE','START LOCATION' ,'END LOCATION'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('OverRool tracking Histary.pdf');
    },

    exportExcel() {
      const tableData = this.trackingData.map((item) => ({
        // '#': index + 1,
        'AGENT NAME': item.agentName,
        'REGISTER NUMBER':item.RegisterNumber,
        'VISITED DATE': item.date,
        'START LOCATION':item.fistAddress,
        'END LOCATION':item.lastAddress,
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Tracking');


      XLSX.writeFile(workbook, 'overRollTracking.xlsx');
    },


    // Get All Tracing Historay
    getAllTracking() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`api/overrolltrackingHistory`, {
        userId: userId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.trackingData = response.data.data;
            console.log('fkfkfk',this.trackingData);

          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });

    },

    getOutletDeteails(firstId,lastId){

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`api/getOutletDetails`, {
        userId: userId,
        firstId:firstId,
        lastId:lastId,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.firstOutlet = response.data.first;
            this.secondOutlet = response.data.first;
          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });

    },

   mapView(item) {
      console.log('item', item);
      this.mapViewDialog = true;

     this.startLocation = item.fistAddress;
     this.endLocation = item.lastAddress;

      this.agentName = item.agentName;
      this.visitedDate = item.date
      this.startTime = item.statTime;
      this.endTime = item.endTime;

      if (item.OverRollTrackingDetails.length > 0) {
        // Map pathCoordinates
        this.pathCoordinates = item.OverRollTrackingDetails.map(point => ({
          lat: parseFloat(point.latitude),
          lng: parseFloat(point.longitude)
        }));

        console.log('trackingdata', this.pathCoordinates);

        // Update the map center to the first point in the history
        this.mapCenter = {
          lat: this.pathCoordinates[0].lat,
          lng: this.pathCoordinates[0].lng
        };

        // Set start and end points
        this.startPoint = this.pathCoordinates[0];
        this.endPoint = this.pathCoordinates[this.pathCoordinates.length - 1];
      }

      this.totalDistance = this.calculateTotalDistance(this.pathCoordinates);
      // console.log(`Total Distance: ${this.totalDistance.toFixed(2)} km`);
    },

    // Function to calculate total distance between the path coordinates using Haversine formula
    calculateTotalDistance(pathCoordinates) {
      // Function to convert degrees to radians
      const toRadians = (deg) => deg * (Math.PI / 180);

      // Haversine formula
      const haversineDistance = (lat1, lng1, lat2, lng2) => {
        const R = 6371; // Radius of the Earth in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLng = toRadians(lng2 - lng1);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                  Math.cos(toRadians(lat1)) * Math.cos(toRadians(lat2)) *
                  Math.sin(dLng / 2) * Math.sin(dLng / 2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
      };

      let totalDistance = 0;

      // Loop through pathCoordinates and sum the distance between consecutive points
      for (let i = 0; i < pathCoordinates.length - 1; i++) {
        const point1 = pathCoordinates[i];
        const point2 = pathCoordinates[i + 1];

        totalDistance += haversineDistance(point1.lat, point1.lng, point2.lat, point2.lng);
      }

      return totalDistance;
    },

    closeD(){
      this.mapViewDialog = false;
    },


    confirmDelete(item) {
       this.deleteAgentId = item.OverRollTrackingDetails[0].agent_id;
       this.deleteDate = item.date;
       this.deleteDialog = true;
    },

    deleteItem() {

      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`api/deletOverRollTrackingDetails`, {
        userId: userId,agentId:this.deleteAgentId,date:this.deleteDate,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.deleteDialog = false;
            this.getAllTracking();
            console.log("deleted",response.data);
            this.showSnackbar("Product delete successfully!", "success");

          })
          .catch(error => {
            console.error('Error fetching Agents:', error);
          });


    },

    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    fetchAllAgents() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/agentUser`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.excludedCategories = response.data.data;
            console.log("API Response:", response.data.data);

            // Check if response.data is an array
            if (Array.isArray(this.excludedCategories)) {
              this.excludedCategories.forEach(agent=> {
                this.agentDataOptions.push({
                  id: agent.id,
                  name: agent.name,
                  regNum: agent.registration_num,
                });
              });
            } else {
              // If it's a single object
              this.agentDataOptions.push({
                id: this.excludedCategories.id,
                name: this.excludedCategories.name,
                regNum: this.excludedCategories.registration_num,
              });
            }

            console.log("API Response ex:", this.excludedCategories);
            console.log('Agent Options:', this.agentDataOptions);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });
    },


  },

}
</script>

<style scoped>

.main-heading {
  height: 10vh;
  padding: 15px;
  margin: 0;
  display: flex;
}

.back-button {
  margin-right: 30px;
  padding: 5px;
}

.sub-heading {
  height: 10vh;
  padding: 10px;
  margin: 0;
}
.map {
  width: 100%;
  height: 100%;

}
.product-details-card{
  height: 100%;
}

.custom-row {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
}

.register-number {
  font-size: 14px;
  color: red; 
}

.card-two-title{
  color: black;
  height: 100%;
  width:100%;
  margin-right: 20px;
}

.red-light-text {
  background-color: #f1cccc;
  color: #b00d0d;
}
.red-text {
  color: #b00d0d;
}

.green-light-text {
  background-color: #aee8b1;
  color: #057b0a;
}
.green-text {
  color: #057b0a;
  background-color: #aee8b1;
}

.sub-heading-card {
  width: 100vw;
  height: 100%;
  padding: 10px;
}

.main-card{
  height: 100%;
  overflow-y: scroll;
  max-height: 65vh;
  margin: 10px;
  padding-bottom: 10px;
}
.text-filed-row{
  height:13vh;
  padding: 20px;
}
.table-row{
  height: 70vh;
}
.table-col{
  height: 95%;
}
.data-table{
  height: 90%;
  border-radius: 10px;
  border: 1px solid rgb(227, 229, 231);
  margin: 20px;
  display: flex;
  justify-content: center;
  justify-items: center;

}
.fc{
  margin-left: 40px;
}
.first-row-dialog{
  height: 5vh;
}
:deep(.data-table) th , td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) {
  border: white;
}

:deep(.data-table )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.data-table )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}


</style>



