<template>
  <v-card class="mt-3 card-size" flat>
    <v-row class="pl-2 pr-6">
      
      <v-col class="mt-3" cols="6">
        <h3 class="page-title">TOTAL LEADS</h3>
        <h5 class="light-gray-text">Analyse leads data in a variety of parameters.</h5>
        <v-card-title>
          
        </v-card-title>
      </v-col>

      <v-col class="px-4 mt-5" cols="6">
        <v-row class="tool-bar-style">
          <v-spacer></v-spacer>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
        </v-row>
      </v-col>

    </v-row>
    <v-card class="px-2 pt-2 mb-3 pb-2 mx-1 leads-details-card" elevation="3">
      <h5 class="card-h4-title px-3 mt-2 mb-4">LEADS ANALYSIS
        <div class="tool-bar-style">
          <v-spacer></v-spacer>
          <v-btn class="red-light-text mr-3" variant="outlined" @click="exportPDF">
            <v-icon class="red-text">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
          </v-btn>
          
          <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
            <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
          </v-btn>
        </div>
      </h5>

      <v-divider class="pb-6"></v-divider>

      <v-row class="px-3">
        <v-col cols="6">
          <v-date-input
              v-model="dates"
              color="red"
              label="Select Date Range"
              max-width="500"
              multiple="range"
              variant="outlined"
              @change="fetchDataintoTable"
          ></v-date-input>
        </v-col>

        <v-col cols="6">
          <v-select
            v-model="filters.visitedStatus"
            :items="visitStatus"
            chips
            clearable
            label="Visited Status"
            multiple
            placeholder="Select status"
            variant="outlined"
            @change="fetchDataintoTable"
          >
          
        </v-select>
        </v-col>

        <!-- <v-col cols="4">
          <v-select
            label="CRM status"
            variant="outlined"
            placeholder="Select status"
            :items="CRMstatus"
            v-model="filters.crm_status"
            multiple
            chips
            @change="fetchDataintoTable"
            clearable
          >
          
        </v-select>
        </v-col> -->
      </v-row>
      <v-col cols="12">
      <v-data-table
        v-model:page="page"
        :headers="headers"
        :items="filteredItems"
        :items-per-page="itemsPerPage"
        :server-items-length="totalItems"
        class="data-table dataTable"
        item-key="id"
        item-value="id"
        @update:page="fetchDataintoTable"
    >
      <template v-slot:[`item.agent_name`]="{ item }">
        <div>
          {{ item.agent_name }}
        </div>
        <div class="red-text">
          Reg num: {{ item.reg_num }}
        </div>
      </template>

      <template v-slot:[`item.visited_status`]="{ item }">
        <v-chip
          :color="item.visited_status === 'Visited' ? 'green' : 'red'"
          class="text-uppercase mt-3"
          dark
          label
          size="small"
        >
          {{ item.visited_status }}
          <v-icon :icon="item.crm_status === 'COMPLETED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>
        </v-chip>
        <div v-if="item.visitedDate" class="red-text mb-3">
          Date - {{ item.visitedDate }}
        </div>
      </template>
      
      <template v-slot:[`item.crm_status`]="{ item }">
        <v-chip
          :color="item.crm_status === 'COMPLETED' ? 'warning' : 'red'"
          class="text-uppercase mt-3"
          dark
          label
          size="small"
        >
          {{ item.crm_status }}
          <v-icon :icon="item.crm_status === 'COMPLETED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>
        </v-chip>
        <div v-if="item.crmDate" class="red-text mb-3">
          Date - {{ item.crmDate }}
        </div>
      </template>
      
      <template v-slot:[`item.actions`]="{ item }" >
              <v-row>
                  <v-btn 
                      class="mr-3 bg-blue-lighten-5 viewbtn"
                      color="blue"
                      variant="outlined"
                      @click="viewItem(item)"
                  >
                    <v-icon>mdi-check-circle</v-icon>&nbsp; view
                  </v-btn>
                  <v-btn
                      class="mr-3 bg-green-lighten-5 "
                      color="green"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="editItem(item)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                      class="bg-red-lighten-5"
                      color="red"
                      icon
                      rounded
                      size="small"
                      variant="outlined"
                      @click="confirmDelete(item)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
              </v-row>
            </template>
    </v-data-table>
    </v-col>
    </v-card>       
      

      <!-- VIEW LEAD DIALOG -->
      <v-dialog v-model="view_leads_dialog" fullscreen>
        <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="view_leads_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">LEADS DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col class="secondC textCenter" cols="12" >
                
                <div class="image card-h4-title">
                  <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                </div>

                <div class="mt-6 ml-6">
                  <v-row>
                    <h3 class="dataCard">Agent name: </h3>
                    <h3 class="dataCard text-red">&nbsp; {{ selectedLead.agent_name }}</h3>
                  </v-row>
                  <v-row class="mt-6">
                    <h5>Mobile : &nbsp; {{ selectedLead.mobile_number }}</h5>
                  </v-row>
                  <v-row class="mt-6">
                    <v-chip
                      :class="selectedLead.visited_status === 'NOT VISITED' ? 'chip1clrTwo' : 'chip1clr'"
                      :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'"
                      text-color="white"
                      variant="outlined"
                    >
                      <v-icon :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'">
                        mdi-check-circle-outline
                      </v-icon>&nbsp;
                      {{ selectedLead.visited_status }}
                    </v-chip> 

                    <v-divider class="mx-3" vertical></v-divider>

                    <v-chip :class="selectedLead.crm_status === 'NOT CREATED' ? 'chip1clrTwo' : 'chip2clr'"
                      :color="selectedLead.crm_status === 'NOT CREATED' ? 'red' : 'warning'"
                      text-color="white"
                      variant="outlined">
                      <v-icon color="warning">mdi-check-circle-outline</v-icon>&nbsp; 
                      CRM ENTRY  &nbsp; {{ selectedLead.crm_status }}
                    </v-chip>
                  </v-row>

                </div>
                <v-spacer></v-spacer>
                <div class="justify-right align-right ml-6">
                  <v-btn class="green-light-text mr-4" variant="outlined" @click="sendSms(selectedLead)">
                    <v-icon class="green-text">mdi-message-processing</v-icon>&nbsp;&nbsp;SEND MESSAGE
                  </v-btn>
                  <v-btn
                    class="bg-red-lighten-4 pa-0"
                    color="red"
                    variant="outlined"
                    @click="confirmDelete(selectedLead)"
                >
                  <v-icon>mdi-trash-can</v-icon>
                </v-btn>
                </div>
                </v-col>
              </v-row>
              
              <v-divider class="my-3"></v-divider>

              <v-row class="pt-6">
                <v-col class="px-6" cols="6">
                  <v-card class="pt-2 card-hight-fixed" flat>
                    <v-col cols="11">
                    <v-text-field
                      v-model="selectedLead.lead_title"
                      class="mb-2"
                      label="Lead Title"
                      placeholder="Type title here"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-select
                      v-model="selectedLead.lead_main_category"
                      class="mb-2"
                      label="Main Category"
                      readonly
                      variant="outlined"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.lead_sub_category"
                      class="mb-2"
                      label="Sub Category"
                      readonly
                      variant="outlined"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.province"
                      class="mb-2"
                      label="Province"
                      readonly
                      variant="outlined"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.district"
                      class="mb-2"
                      label="District"
                      readonly
                      variant="outlined"
                    ></v-select>

                    <v-textarea
                      v-model="selectedLead.lead_description"
                      class="mb-2"
                      label="Lead Description"
                      readonly
                      variant="outlined"
                    ></v-textarea>
                  </v-col>
                  </v-card>
                </v-col>
                <v-col class="px-6" cols="6">
                  <v-card class="pt-2 card-hight-fixed" flat>
                    <v-col cols="11">
                    <v-select
                      v-model="selectedLead.dsd"
                      class="mb-2"
                      label="DSD"
                      readonly
                      variant="outlined"
                    ></v-select>

                    <v-select
                        v-model="selectedLead.gnd"
                        class="mb-2"
                        label="GND"
                        readonly
                        variant="outlined"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.agent_name"
                      class="mb-2"
                      label="Assign Agent"
                      readonly
                      variant="outlined"
                    ></v-select>
                    
                    <v-text-field
                      v-model="selectedLead.assign_date"
                      class="mb-2"
                      label="Assign Date"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-divider></v-divider>
                    <h5 class="px-3 py-3">SELECTED LEADS LIST</h5>
                    <h6 class="px-3 pb-3 light-gray-text">The following table shows all the leads related to your leads category & you can add only the leads you like.</h6>
                    <v-card class="ma-3">
                      
                        <v-divider></v-divider>
                        <v-card class="ma-3">
                          <v-data-table
                              :headers="headers_two"
                              :items="selectedLead.selected_lead"
                              :items-per-page="itemsPerPage"
                              :search="search"
                              class="data-table elevation-1"
                            >
                              <template v-slot:[`item.sub_category`]="{ item }">
                                <span class="red-text">{{ item.sub_category }}</span>
                              </template>

                            <template v-slot:[`item.crmStatus`]="{ item }">
                              <span class="red-text">Not Compeleted{{item.sa}}</span>
                            </template>


                               <!-- Template for Status Column -->
                              <template v-slot:[`item.visited_status`]="{ item }">
                                <v-chip
                                  :color="item.visited_status === 'VISITED' ? 'green' : 'red'"
                                  class="text-uppercase mt-3"
                                  dark
                                  label
                                  size="small"
                                >
                                  {{ item.visited_status }}
                                  <v-icon :icon="item.visited_status === 'VISITED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>
                                </v-chip>
                              </template>

                              <!-- Template for Visit Date Column -->
                              <template v-slot:[`item.visitDate`]="{ item }">
                                <span v-if="item.visited_status === 'VISITED'">{{ item.visitDate }}</span>
                                <!-- <v-btn v-else class="red-white-text" dark @click="sendReminder(item)">REMINDER</v-btn> -->
                                <v-btn v-else class="red-white-text" dark @click="openReminderDialog(item)">REMINDER</v-btn>
                              </template>
                          </v-data-table>
                        </v-card>
                    </v-card>
                  </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

        </v-card>
      </v-dialog>

      <!-- Edit LEAD DIALOG -->
      <v-dialog v-model="edit_leads_dialog" fullscreen>
        <v-card class="card-size">
        <v-toolbar class="pr-5 mb-5" color="white" dense>
          <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="#b00d0d" dark variant="elevated" @click="edit_leads_dialog = false">
            <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
          </v-btn>
        </v-toolbar>
        <v-divider class="divider-margin-dv"></v-divider>
        <v-row class="mt-0">
          <v-col cols="12" md="12">
            <v-card class="pa-3 mx-6">
            <v-icon>mdi-view-dashboard
            </v-icon>
            <v-label class="ml-6">UPDATE DETAILS</v-label>
            </v-card>
            <v-card class="pa-3 mx-6 mt-3">
              <v-row class="first_row ma-0 pa-0 ">
                <v-col class="secondC textCenter" cols="12" >
                
                <div class="image card-h4-title">
                  <img :src="require('@/assets/ASSETS/PERSONS/person-3.jpg')" class="avatar-image"/>
                </div>

                <div class="mt-6 ml-6">
                  <v-row>
                    <h3 class="dataCard">Agent name: </h3>
                    <h3 class="dataCard text-red">&nbsp; {{ selectedLead.agent_name }}</h3>
                  </v-row>
                  <v-row class="mt-6">
                    <h5>Mobile : &nbsp; {{ selectedLead.mobile_number }}</h5>
                  </v-row>
                  <v-row class="mt-6">
                    <v-chip
                      :class="selectedLead.visited_status === 'NOT VISITED' ? 'chip1clrTwo' : 'chip1clr'"
                      :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'"
                      text-color="white"
                      variant="outlined"
                    >
                      <v-icon :color="selectedLead.visited_status === 'NOT VISITED' ? 'red' : 'green'">
                        mdi-check-circle-outline
                      </v-icon>&nbsp;
                      {{ selectedLead.visited_status }}
                    </v-chip> 

                    <v-divider class="mx-3" vertical></v-divider>

                    <v-chip :class="selectedLead.crm_status === 'NOT CREATED' ? 'chip1clrTwo' : 'chip2clr'"
                      :color="selectedLead.crm_status === 'NOT CREATED' ? 'red' : 'warning'"
                      text-color="white"
                      variant="outlined">
                      <v-icon color="warning">mdi-check-circle-outline</v-icon>&nbsp; 
                      CRM ENTRY  &nbsp; {{ selectedLead.crm_status }}
                    </v-chip>
                  </v-row>

                </div>
                <v-spacer></v-spacer>
                <div class="justify-right align-right ml-6">
                  <v-btn class="bg-red-lighten-4" color="red" size="large" variant="outlined" @click="updateLead()">UPDATE LEADS DATA</v-btn>&nbsp; &nbsp;
                  <v-btn class="bg-grey-lighten-2" size="large" variant="outlined" @click="edit_leads_dialog = false">CANCEL</v-btn>
                </div>
                </v-col>
              </v-row>
              
              <v-divider class="my-3"></v-divider>

              <v-row class="pt-6">
                <v-col class="px-6" cols="6">
                  <v-card class="pt-2 card-hight-fixed" flat>
                    <v-col cols="11">
                    <v-text-field
                      v-model="selectedLead.lead_title"
                      class="mb-2"
                      label="Lead Title *"
                      placeholder="Type title here"
                      readonly
                      variant="outlined"
                    ></v-text-field>

                    <v-select
                      v-model="selectedLead.lead_main_category"
                      :items="categories"
                      :loading="mainFilterLoading"
                      class="mb-2"
                      item-text="main_filter"
                      item-value="id"
                      label="Main Category *"
                      placeholder="Select main category"
                      readonly
                      variant="outlined"
                      @update:modelValue="fetchSubCategories(selectedLead.lead_main_category)"
                    ></v-select>

                    <v-select
                        v-model="selectedLead.lead_sub_category"
                        :items="subCategories"
                        :loading="subFilterLoading"
                        :v-if="isDisabledProvinceList"
                        class="mb-2"
                        item-text="sub_filter"
                        item-value="id"
                        label="Sub Category *"
                        placeholder="Select sub category"
                        readonly
                        variant="outlined"
                    ></v-select>

                    <v-row>
                      <v-col cols="12" md="10">
                        <h5 class="px-3 py-3">LEADS LIST</h5>
                      </v-col>
                      <v-col cols="12" md="2">
                        <v-btn class="bg-red-lighten-4 mt-3" @click="confirmDeleteList()">
                          <v-icon color="red">mdi-trash-can</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <h6 class="px-3 pb-3 light-gray-text">The following table shows all the leads related to your leads category & you can add only the leads you like.</h6>
                    
                    <v-card v-if="showLeadsCard" class="my-3 mx-0" flat>
                      <v-divider></v-divider>
                      <v-card class="mx-1">
                        <v-data-table
                          :headers="headers_two"
                          :items="selectedLead.selected_lead"
                          :items-per-page="itemsPerPage"
                          :search="search"
                          class="data-table elevation-1"
                        >
                          <template v-slot:[`item.sub_category`]="{ item }">
                            <span class="red-text">{{ item.sub_category }}</span>
                          </template>

                          <template v-slot:[`item.crmStatus`]="{ item }">
                            <span class="red-text">Not Compeleted{{item.sa}}</span>
                          </template>

                          <!-- Template for Status Column -->
                          <template v-slot:[`item.visited_status`]="{ item }">
                            <v-chip
                              :color="item.visited_status === 'VISITED' ? 'green' : 'red'"
                              class="text-uppercase mt-3"
                              dark
                              label
                              size="small"
                            >
                              {{ item.visited_status }}
                              <v-icon :icon="item.visited_status === 'VISITED' ? 'mdi-check-bold' : 'mdi-close-thick'" class="ml-2"></v-icon>
                            </v-chip>
                          </template>

                          <!-- Template for Visit Date Column -->
                          <template v-slot:[`item.visitDate`]="{ item }">
                            <span v-if="item.visited_status === 'VISITED'">{{ item.visitDate }}</span>
                            <!-- <v-btn v-else class="red-white-text" dark @click="sendReminder(item)">REMINDER</v-btn> -->
                            <v-btn v-else class="red-white-text" dark @click="openReminderDialog(item)">REMINDER</v-btn>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-card>

                    <!-- Card to be displayed after delete -->
                    <v-card v-if="!showLeadsCard" class="ma-3">
                      <v-toolbar color="white">
                        <v-toolbar-title>
                          <v-switch v-model="selectAll" label="All" @change="toggleSelectAll"></v-switch>
                        </v-toolbar-title>
                        <v-divider vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-col cols="8">
                          <v-text-field
                            v-model="search"
                            append-inner-icon="mdi-magnify"
                            clearable
                            hide-details
                            label="Search"
                            placeholder="Search Lead"
                            variant="outlined"
                          ></v-text-field>
                        </v-col>
                      </v-toolbar>
                      <v-divider></v-divider>
                      <v-card class="ma-3">
                        <v-data-table
                          :headers="headersTwoT"
                          :items="filteredItems"
                          :items-per-page="itemsPerPage"
                          :search="search"
                          class="data-table elevation-1"
                        >
                          <template v-slot:[`item.data-table-select`]="{ item }">
                            <v-switch v-model="item.selected" class="mt-0" hide-details></v-switch>
                          </template>
                          <template v-slot:[`item.subCategory`]="{ item }">
                            <span class="red-text">{{ item.subCategory }}</span>
                          </template>
                        </v-data-table>
                      </v-card>
                    </v-card>

                    
                    <v-textarea
                        v-model="selectedLead.lead_description"
                        class="mb-2 mt-6"
                        label="Lead Description"
                        placeholder="Write a description here..."
                        variant="outlined"
                    ></v-textarea>
                    </v-col>
                  </v-card>
                </v-col>
                <v-col class="px-6" cols="6">
                  <v-card class="pt-2 card-hight-fixed" flat>
                    <v-col cols="11">
                    <v-select
                      v-model="selectedLead.province"
                      :items="provinces"
                      :loading="mDistrictLoading"
                      class="mb-2"
                      item-text="label"
                      item-value="value"
                      label="Province"
                      placeholder="Select province"
                      readonly
                      variant="outlined"
                      @update:modelValue="getAllDistrictAccProv"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.district"
                      :disabled="isDisabledDistrictList"
                      :items="districts"
                      :loading="mDistrictLoading"
                      class="mb-2"
                      item-text="label"
                      item-value="value"
                      label="District"
                      placeholder="Select district"
                      readonly
                      variant="outlined"
                      @update:modelValue="getAllDsd"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.dsd"
                      :disabled="isDisabledDSDLIST"
                      :items="dsdList"
                      :loading="mDSDLoading"
                      class="mb-2"
                      item-text="label"
                      item-value="value"
                      label="Divisional"
                      placeholder="Select divisional"
                      readonly
                      variant="outlined"
                      @update:modelValue="getAllGnd"
                    ></v-select>

                    <v-select
                      v-model="selectedLead.gnd"
                      :disabled="isDisabledGNDList"
                      :items="gndList"
                      :loading="mDSDLoading"
                      class="mb-2"
                      label="GND"
                      placeholder="Select GND"
                      readonly
                      variant="outlined"
                      @update:modelValue="loadLeadsList(lead.mainCategory, lead.subCategory)"
                    ></v-select>


                    <v-select
                      v-model="selectedLead.agent_name"
                      :items="agents"
                      class="mb-2"
                      item-title="name"
                      item-value="name"
                      label="Assign Agent *"
                      placeholder="Select agent"
                      variant="outlined"
                    ></v-select>

                    <v-date-input
                      v-model="selectedLead.assign_date"
                      color="red"
                      label="Assign Date"
                      max-width="580"
                      persistent-placeholder
                      placeholder="Select a Date"
                      variant="outlined"
                    ></v-date-input>

                  </v-col>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>

          </v-col>
        </v-row>

        </v-card>
      </v-dialog>

      <!-- Send reminder for not visited records -->
      <v-dialog v-model="reminderDialog"  max-height="690" max-width="400" persistent>
        <v-card class="pa-1">
          <v-row class="rowClr mb-1 mt-3 mx-3" dense>
            <v-col cols="12" md="9">
              <v-card-title>SEND REMINDER</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="reminderDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3"></v-divider>

          <v-card>
            <v-tabs
              v-model="tab"
              bg-color="white"
            >
              <v-tab value="one">REASON</v-tab>
              <v-tab value="two">SEND REMINDER</v-tab>
            </v-tabs>

            <v-card-text>
              <v-tabs-window v-model="tab">
                <v-tabs-window-item value="one">
                
                    <v-textarea
                      label="Outlined textarea"
                      name="input-7-4"
                      outlined
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                    ></v-textarea>
                    
                </v-tabs-window-item>

                <v-tabs-window-item value="two">
                  <v-card>
                  <v-text-field
                    v-model="selectedLead.lead_title"
                    :rules="[v => !!v || 'Lead Title is required']"
                    class="mb-0 mt-3 mx-3"
                    density="compact"
                    label="Lead Title"
                    placeholder="Type Lead Title"
                    required
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="selectedItem.institute_name"
                    :rules="[v => !!v || 'Outlet Name is required']"
                    class="mb-0 mx-3"
                    density="compact"
                    label="Outlet Name"
                    placeholder="Type Outlet Name"
                    required
                  variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="selectedItem.address"
                    :rules="[v => !!v || 'Outlet Address is required']"
                    class="mb-0 mx-3"
                    density="compact"
                    label="Outlet Address"
                    placeholder="Type Outlet Address"
                    required
                  variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="selectedLead.agent_name"
                    :rules="[v => !!v || 'Assignee is required']"
                    class="mb-0 mx-3"
                    density="compact"
                    label="Assignee"
                    placeholder="Type Assignee"
                    required
                  variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="selectedLead.mobile_number"
                    :rules="[v => !!v || 'Mobile number is required']"
                    class="mb-0 mx-3"
                    density="compact"
                    label="Mobile"
                    placeholder="Type Mobile Number Here"
                    required
                    variant="outlined"
                  ></v-text-field>
                  <v-text-field
                    v-model="selectedItem.email"
                    :rules="[v => !!v || 'Email is required']"
                    class="mb-0 mx-3"
                    density="compact"
                    label="Email"
                    placeholder="Type Email Here"
                    required
                    variant="outlined"
                  ></v-text-field>
                  <v-textarea
                    v-model="selectedItem.note"
                    :rules="[v => !!v || 'Note is required']"
                    class="mb-0 mx-3"
                    density="compact"
                    label="Note"
                    placeholder="Type Note Here"
                    required
                    rows="3"
                    variant="outlined"
                  ></v-textarea>
                  <v-divider></v-divider>
                <v-card-actions class="mr-1">
                  <v-spacer></v-spacer>
                  <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="sendReminder">SEND</v-btn>&nbsp; &nbsp;
                  <v-btn class="bg-grey-lighten-2" variant="outlined" @click="reminderDialog = false">CANCEL</v-btn>
                </v-card-actions>
              </v-card>
                </v-tabs-window-item>

                
              </v-tabs-window>
            </v-card-text>
          </v-card>
          
        </v-card>
      </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteLeadDialog" max-height="500" max-width="500" persistent>
        <v-card class="pa-1">
          <v-row class="rowClr ma-1" dense>
            <v-col cols="12" md="9">
              <v-card-title>DELETE LEAD</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="deleteLeadDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this Lead ?</v-card-text>
          <v-card-text class="textspace">
            This lead will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>&nbsp; &nbsp;
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteLeadDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteLeadListEditDialog" max-height="500" max-width="500" persistent>
        <v-card class="pa-1">
          <v-row class="rowClr ma-1" dense>
            <v-col cols="12" md="9">
              <v-card-title>DELETE LEAD</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="deleteLeadListEditDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this leads list ?</v-card-text>
          <v-card-text class="textspace">
            This data set will be deleted immediately. Once deleted, it can no longer be used in any purpose.
          </v-card-text>
          <v-divider class="mb-3"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteLeadsListItem">DELETE</v-btn>&nbsp; &nbsp;
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteLeadListEditDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- sms dialog -->
      <v-dialog v-model="smsDialog" max-height="600" max-width="400" persistent>
        <v-card class="pa-1">
          <v-row class="rowClr mb-1 mt-3 mx-3" dense>
            <v-col cols="12" md="9">
              <v-card-title>SEND MESSAGE</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end pt-3 pr-3" cols="12" md="3">
              <v-btn icon size="x-small" @click="smsDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-divider class="mt-3"></v-divider>

          <v-text-field
            v-model="selectedItem.mobile_number"
            :rules="[v => !!v || 'Mobile number is required']"
            class="mb-1 mt-3 mx-3"
            label="Mobile"
            placeholder="Mobile number"
            required
            variant="outlined"
          ></v-text-field>

          <v-text-field
            v-model="selectedItem.email"
            :rules="[v => !!v || 'Email is required']"
            class="mb-1 mx-3"
            label="Email"
            placeholder="Email address"
            required
            variant="outlined"
          ></v-text-field>

          <v-textarea
              v-model="selectedItem.note"
              :rules="[v => !!v || 'Note is required']"
              class="mb-1 mx-3"
              label="Note"
              placeholder="Type your massage here..."
              required
              variant="outlined"
          ></v-textarea>

          <v-divider class="mb-3"></v-divider>
          <v-card-actions class="mr-1">
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="sendSMSSubmit">SEND</v-btn>&nbsp; &nbsp;
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="smsDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- Snackbar to show notifications -->
    <v-snackbar
      v-model="snackAlert.snackbar"
      :color="snackAlert.snackColor"
      :right="snackAlert.snackBarRight"
      :top="snackAlert.snackBarTop"
      timeout="3000"
    >
      {{ snackAlert.snackMessage }}
      
    </v-snackbar>
    </v-card>
  </template>
    
    <script>
    import axios from 'axios';
    import _ from 'lodash';
    import jsPDF from 'jspdf';
    import 'jspdf-autotable';
    import * as XLSX from "xlsx";
    export default {
      components: {

      },
      name: 'LeadsList',
      data() {
        return {
          tab: null,
          smsMobile: '',
          smsEmail: '',
          smsNote: '',

          headers: [
            { text: 'Category', value: 'lead_sub_category', sortable: true, title: 'Category' },
            { text: 'Agent Name', value: 'agent_name', sortable: true, title: 'Agent Name' },
            { text: 'Agent Visited Status', value: 'visited_status', sortable: true, title: 'Agent Visited Status' },
            // { text: 'CRM Status', value: 'crm_status', sortable: true, title: 'CRM Status' },
            { text: 'Assign Date', value: 'assign_date', sortable: true, title: 'Assign Date' },
            { text: 'Action', value: 'actions', sortable: false, title: 'Action' }
          ],
          items: [],
          // items: [], // Items fetched from API
          totalItems: 5, // Set the correct total number of items
          page: 1,
          itemsPerPage: 10,
          filters: {
            startDate: '',
            endDate: '',
            visitedStatus: [],
            crm_status: []
          },
          visitStatus: ['VISITED', 'NOT VISITED'],
          CRMstatus: ['COMPLETED', 'NOT COMPLETED'],


          view_leads_dialog: false,

          edit_leads_dialog: false,

          deleteLeadDialog: false,
          deleteLeadListEditDialog: false,

          reminderDialog: false,

          selectedItem: {},

          smsDialog: false,
          dates: [],

          //addleads
          lead: {
            title: '',
            mainCategory: '',
            sub_category: '',
            province: '',
            district: '',
            description: '',
            dsd: '',
            gnd: '',
            assignAgent: '',
            assign_date: '',
          },
        
          file: null,

          headersTwoT: [
            { text: '', value: 'data-table-select', title: 'data-table-select' },
            { text: 'Sub Category', value: 'subCategory', sortable: true, title: 'Sub Category' },
            { text: 'Institute Name', value: 'instituteName', sortable: true, title: 'Institute Name' },
            { text: 'Address', value: 'address', sortable: true, title: 'Address' },
          ],

          // add leads
          headers_two: [
            // { text: '', value: 'data-table-select', title: 'data-table-select' },
            { text: 'Sub Category', value: 'sub_category', sortable: true, title: 'Sub Category' },
            { text: 'Institute Name', value: 'institute_name', sortable: true, title: 'Institute Name' },
            { text: 'Address', value: 'address', sortable: true, title: 'Address' },
            { text: 'Status', value: 'visited_status', sortable: true, title: 'Status' },
            { text: 'CRM Status', value: 'crmStatus', sortable: true, title: 'CRM Status' },
            { text: 'Visit Date', value: 'visitDate', sortable: true, title: 'Visit Date' },
          ],
          // items_two: [],
          items_two: [],

          showLeadsCard: true, 

          // add leads bulk upload
          headers_dialog: [
            { text: 'Main Category', value: 'mainCategory', title: 'Main Category'},
            { text: 'Sub Category', value: 'sub_category', title: 'Sub Category'},
            { text: 'Province', value: 'province', title: 'Province'},
            { text: 'District', value: 'district', title: 'District'},
            { text: 'DSD', value: 'dsd', title: 'DSD'},
            { text: 'GND', value: 'gnd', title: 'GND'},
            { text: 'Assign Agent', value: 'assignAgent', title: 'Assign Agent'},
            { text: 'Assign Date', value: 'assign_date', title: 'Assign Date'},
            { text: 'Description', value: 'description', title: 'Description'},
            { text: 'Lead List', value: 'leadList', title: 'Lead List'},
          ],
          // items_dialog: [],
          items_dialog: [],
        
          //search
          search: '',

          // Dialog state
          leadsBulkUploadDialog: false,

          //select date
          assignDateMenu: false,

          selectedLeadId: null,
          selectedLead: null, // Store current lead details
          selectedLeadEdit: null, // Store current lead details for edit

          snackAlert: {
            snackbar: false, // Snackbar visibility
            snackColor: "", // Snackbar color
            snackMessage: "", // Snackbar message
            snackBarRight: false, // Snackbar position right
            snackBarTop: true,  // Snackbar position top
          },
          selectedCategory: null,
          selectAll: false,

          mainFilterLoading: false,
          subFilterLoading: false,

          mDistrictLoading: false,
          mDSDLoading: false,
          mGNDLoading: false,
          provinces: [],
          districts: [],
          dsdList: [],
          gndList: [],


          
          isDisabledProvinceList:false,
          isDisabledDistrictList:false,
          isDisabledDSDLIST:false,
          isDisabledGNDList:false,
          
          selectedDistrict:[],
          selectedProvince:"",
          selectedDSD:[],
          selectedGND:[],

          agents: [], 

          categories: [], 
          subCategories: [],
          allCategories:[],
        };
      },
  //     watch: {
  //   'selectedLead.lead_main_category': function(newValue) {
  //     // Example of using newValue
  //     console.log('Main Category changed to:', newValue);
  //     this.fetchLeads();
  //   },
  //   'selectedLead.lead_sub_category': function(newValue) {
  //     console.log('Sub Category changed to:', newValue);
  //     this.fetchLeads();
  //   },
  //   'lead.province': function(newValue) {
  //     console.log('Province changed to:', newValue);
  //     this.fetchLeads();
  //   },
  //   'lead.district': function(newValue) {
  //     console.log('District changed to:', newValue);
  //     this.fetchLeads();
  //   },
  //   'lead.dsd': function(newValue) {
  //     console.log('DSD changed to:', newValue);
  //     this.fetchLeads();
  //   },
  //   'lead.gnd': function(newValue) {
  //     console.log('GND changed to:', newValue);
  //     this.fetchLeads();
  //   }
  // },

      computed: {
        filteredItems() {
          let filtered = this.items;
                // Apply filters to items
          if (this.filters.startDate && this.filters.endDate) {
            //
          }

          if (this.filters.visitedStatus.length) {
            filtered = filtered.filter(item =>
              this.filters.visitedStatus.includes(item.visited_status)
            );
          }

          if (this.filters.crm_status.length) {
            filtered = filtered.filter(item =>
              this.filters.crm_status.includes(item.crm_status)
            );
          }

          return filtered;
        }
      },

      created(){
        this.fetchDataintoTable();
      },

      mounted() {
        this.fetchMainCategories();
        this.getAllProvinces();
        this.fetchAllAgents();
        // this.loadLeadsList('All','province',null);
      },

  methods: {
    showSnackbar(message, color) {
      this.snackAlert.snackbar = true;
      this.snackAlert.snackMessage = message;
      this.snackAlert.snackColor = color;
    },

    exportExcel() {
        const tableData = this.items.map((item, index) => ({
          '#': index + 1,
          'Category': item.lead_sub_category,
          'Agent Name': item.agent_name,
          'Agent Visited Status': item.visited_status,
          'Assign Date': item.assign_date,
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Leads');


        XLSX.writeFile(workbook, 'leads_analysis.xlsx');
      },

      exportPDF() {
        const doc = new jsPDF();

        // Set the title for the document
        doc.text('Lead Analysis', 14, 10);

        // Prepare the data for the table
        const tableData = this.items.map((item, index) => [
          index + 1,
          item.lead_sub_category,
          item.agent_name,
          item.visited_status,
          item.assign_date,
        ]);

        // Define the columns for the table
        const tableColumns = ['#', 'Category', 'Agent Name', 'Agent Visited Status', 'Assign Date'];

        // AutoTable plugin to create a table
        doc.autoTable({
          head: [tableColumns],
          body: tableData,
        });

        // Save the PDF with a name
        doc.save('leads_analysis.pdf');

      },


    toggleSelectAll() {
      this.filteredItems.forEach(item => {
        item.selected = this.selectAll;
      });
    },
    
    confirmDeleteList() {
      this.deleteLeadListEditDialog = true;
      // Perform delete operation here
    },

    deleteLeadsListItem(){
      // Then update card visibility
      this.showLeadsCard = false;
      this.deleteLeadListEditDialog = false;
    },

    // Call this method whenever mainCategory, subCategory, or location fields change
    // async fetchLeads() {
    //   await this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
    // },

    // async loadLeadsList(mainCategory, subCategory) {
    //   try {
    //     // Determine the location_type and location values based on selected values
    //     let locationType = '';
    //     let locationValue = [];

    //     if (this.lead.gnd && this.lead.gnd.length > 0) {
    //       locationType = 'gnd';
    //       locationValue = [this.lead.gnd];
    //     } else if (this.lead.dsd && this.lead.dsd.length > 0) {
    //       locationType = 'dsd';
    //       locationValue = [this.lead.dsd];
    //     } else if (this.lead.district && this.lead.district.length > 0) {
    //       locationType = 'district';
    //       locationValue = [this.lead.district];
    //     } else if (this.lead.province && this.lead.province !== "") {
    //       locationType = 'province';
    //       locationValue = [this.lead.province];
    //     } 
    //     // Construct the payload
    //     const payload = {
    //       type: 'Spatial',
    //       country: 'SriLanka',
    //       district: locationType === 'district' ? locationValue : [],
    //       province: locationType === 'province' ? locationValue : [],
    //       dsd: locationType === 'dsd' ? locationValue : '',
    //       gnd: locationType === 'gnd' ? locationValue : '',
    //       location_type: locationType,
    //       main_filter: mainCategory,
    //       sub_filter: subCategory
    //     };
        

    //     // Make the API call
    //     const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getGISData', payload);

    //     console.log('table data for leads:', payload);
    //     if (response.data.http_status === 'success') {
    //       console.log('table data for leads two:', payload);
    //       // Map the response data to your table structure
    //       // this.filteredItems = response.data.data.map(item => ({
    //       //   subCategory: item.Type,
    //       //   instituteName: item.Name,
    //       //   address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`
    //       // }));

    //       this.items = response.data.data.map(item => ({
    //         subCategory: item.Type,
    //         instituteName: item.Name,
    //         address: `${item.GND_N}, ${item.DSD_N}, ${item.DISTRICT_N}, ${item.PROVINCE_N}`,
    //         selected: false // Add a default value for selection
    //       }));
    //     } else {
    //       console.error("Failed to load leads:", response.data.message);
    //     }
    //   } catch (error) {
    //     console.error("Error fetching leads:", error);
    //   }
    // },


    fetchAllAgents() {
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem('token'); 

        axios.post('/api/agentUser', {
            userId: userId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'content-type': 'multipart/form-data',
            }
        })
        .then(response => {
            let sample = response.data.data;
            this.agents = sample.map(item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
            console.log('Agents:', this.agents);
        })
        .catch(error => {
            console.error('Error fetching Agents:', error);
        });
    },

    getAllProvinces() {
        this.districts = [];
        this.dsdList = [];
        this.gndList = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'province' }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.provinces = response.data.data;
                this.mDistrictLoading = false;
              }
            })
            .catch(() => {
              this.mDistrictLoading = false;
            });
      },

      getAllDistrictAccProv(province) {
        console.log('Province selected:', province);
        this.districts = [];
        this.mDistrictLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const headers = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'district', value: province }, headers)
            .then(response => {
              console.log('District API response:', response.data);
              if (response.data.http_status === 'success') {
                this.districts = response.data.data;
                console.log('District API response:', response.data);
                this.mDistrictLoading = false;
                this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
                console.log('main c:', this.selectedLead.lead_main_category);
                console.log('sub c:', this.selectedLead.lead_sub_category);
              }
            })
            .catch(error => {
              this.mDistrictLoading = false;
              console.error('Error fetching districts:', error);

            });
      },

      getAllDsd(district) {
        this.dsdList = [];
        this.mDSDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'dsd', value: district }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.dsdList = response.data.data;
                this.mDSDLoading = false;
                this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
                console.log('main c:', this.selectedLead.lead_main_category);
                console.log('sub c:', this.selectedLead.lead_sub_category);
              }
            })
            .catch(() => {
              this.mDSDLoading = false;
            });
      },

      getAllGnd(dsd) {
        this.gndList = [];
        this.mGNDLoading = true;
        // let laravel = JSON.parse(window.Laravel);
        const header = {
          // 'X-CSRF-TOKEN': laravel.csrfToken,
          'X-Requested-With': 'XMLHttpRequest',
          'content-type': 'multipart/form-data',
        };

        axios.post('https://geobizz.tech/api/gis/sortAdminBoundary', { type: 'gnd', value: dsd }, header)
            .then(response => {
              if (response.data.http_status === 'success') {
                this.gndList = response.data.data;
                this.mGNDLoading = false;
                this.loadLeadsList(this.selectedLead.lead_main_category, this.selectedLead.lead_sub_category);
                console.log('main c:', this.selectedLead.lead_main_category);
                console.log('sub c:', this.selectedLead.lead_sub_category);
              }
            })
            .catch(() => {
              this.mGNDLoading = false;
            });
      },

    async fetchMainCategories() {
      try {
        this.mainFilterLoading = true;
        const response = await axios.post('https://geobizztech.celata.org/geoapi/gis/getCategories', {
          org_code: 'SFA_Backend',
          category: 'Community data',
          type: 'Spatial',
          country: 'SriLanka',
          user: ''
        });

        // Log the entire response object
        console.log('API Response for Main Categories:', response);
        console.log('API Response for Main Categories:', response.data);
        console.log('Categories Dataset:', response.data.dataset);

        if (response.data && response.data.dataset) {
          this.allCategories = response.data.dataset;
          this.mainGroup = _.groupBy(this.allCategories, 'main_filter');

          this.categories = Object.keys(this.mainGroup);
          this.mainFilterLoading = false;

          this.geoJsonLayers = this.allCategories
            .filter(val => val.spatial_type !== 'Point')
            .map(val => ({
              data: null,
              name: val.sub_filter,
              main_type: val.main_filter,
              sub_directory: val.data_model
            }));

          // Log the mapped categories
          console.log('Mapped Categories:', this.categories);

        } else {
          throw new Error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error fetching main categories:', error);
        this.snackAlert.snackbar = true;
        this.snackAlert.snackColor = 'red';
        this.snackAlert.snackMessage = 'Failed to load main categories!';
      } finally {
        this.mainFilterLoading = false;
      }
    },

    fetchSubCategories(dataset){
      // if (filter === 'main'){
          this.isDisabledDistrictList = false;
          this.isDisabledDSDLIST = false;
          this.isDisabledGNDList = false;
          this.lead.province = null;
          this.lead.district = [];
          this.lead.dsd = [];
          this.lead.gnd = [];
          // if (selectedLead.lead_sub_category) {
          //   this.selectedLead.lead_sub_category="";
          // }
          // if (selectedLead.lead_sub_category) {
            this.selectedLead.lead_sub_category="";
          // }
          

          // Debugging: Check the input parameters
          // console.log('Filter:', filter);
          console.log('Dataset:', dataset);

          // Ensure that `this.allCategories` is correctly populated
          console.log('All Categories:', this.allCategories);
          
          let sub_filter = _.map(this.allCategories,function (o) {
              if (o.main_filter === dataset )
                  return o.sub_filter
          });

          sub_filter = _.reject(sub_filter, _.isNil);
          this.subCategories = sub_filter.sort();

          // Debugging: Verify the final result
           console.log('Sub Categories:', this.subCategories);
      
    },

        fetchDataintoTable(){
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem("token");

          const formData = new FormData();
          formData.append("userId", userId);

          axios.post("/api/getLeadList", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
            }).then((response) => {

              this.items = response.data.data.map(lead => {
                // Parse the assign_date and format it
                // const assignDate = new Date(lead.assign_date);
                // const formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;

                let formattedDate = null;
                if (lead.assign_date) {
                  const assignDate = new Date(lead.assign_date);
                  formattedDate = `${assignDate.getFullYear()}-${String(assignDate.getMonth() + 1).padStart(2, '0')}-${String(assignDate.getDate()).padStart(2, '0')}`;
                }
                this.filterDataByDateRange();

                // Return a new object with the formatted date
                return {
                  ...lead,
                  assign_date: formattedDate
                };
                
              }).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)); // Sort by created_at descending
                      // this.items= response.data.data;
                      // // this.totalItems = response.data.totalItems;
                      console.log('lead list data',this.items);
                    })
                    .catch((error) => {
                       

                        console.log(error);
                        
                    });
          },

        filterDataByDateRange() {
          if (this.dates.length === 2) { // Ensure there are start and end dates
            const [startDate, endDate] = this.dates.map(date => new Date(date).toISOString().split('T')[0]);
            
            this.items = this.allItems.filter(lead => {
              const assignDate = lead.assign_date ? new Date(lead.assign_date).toISOString().split('T')[0] : null;
              return assignDate && assignDate >= startDate && assignDate <= endDate;
            });
          } else {
            // If no date range is selected, show all items
            this.items = this.allItems;
          }
        },

        backPageRoute(){
          this.$router.back();
        },
        homeReload(){
          window.location.href = '/dashboard';
        },
        
        viewItem(item) {
          this.selectedLead = item; 
          this.view_leads_dialog = true;
          console.log('View item:', item);
        },
        editItem(item) {
          this.selectedLead = { ...item }; // Ensure selectedLead is a new object with the item's data 
          this.edit_leads_dialog = true;
          console.log('Editing item:', item);
        },

        sendSms(item) {
          this.selectedItem = { ...item };
          // this.smsMobile = this.selectedItem.mobile_number;
          // this.smsEmail = this.selectedItem.email;
          // this.smsNote = this.selectedItem.note;
          this.smsDialog = true;
        },
        sendSMSSubmit() {
          // Validate required fields
          if (!this.selectedItem.mobile_number || !this.selectedItem.email || !this.selectedItem.note) {
            // Show snackbar message
            this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
            this.snackAlert.snackColor = 'red';
            this.snackAlert.snackVisible = true;
            return; // Stop the function execution if validation fails
          }
          // Extract the required data
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');
          const sendSMSTo = {
            mobileNumber: this.selectedItem.mobile_number,
            email: this.selectedItem.email,
            note: this.selectedItem.note,
            userID: userId
          };
            // Log the reminder data
              console.log("SMS sent to:", this.selectedItem);

            // Make API call using axios
            axios.post('/api/message', sendSMSTo, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
              console.log('Success:', response.data);
              // Handle success response if needed
              this.smsDialog = false;
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error response if needed
            });
        },

        updateLead(){
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');

          // const selectedLeads = this.filteredItems.filter(item => item.selected);
          // const selectedLeadsJSON = JSON.stringify(selectedLeads);

          // Gather selected leads and map to the required format
          const selectedLeads = this.items
            .filter(item => item.selected)
            .map((item, index) => ({
              id: index + 1, // Assigning a unique ID (you may adjust this as needed)
              instiName: item.instituteName,
              address: item.address
            }));

          // Convert the mapped leads to a JSON string

          const selectedLeadsJSON = JSON.stringify(selectedLeads);

          const leadData = {
            leadsTitle: this.selectedLead.lead_title,
            leadId: this.selectedLead.id,
            mainCatagory: this.selectedLead.lead_main_category,
            subCatagory: this.selectedLead.lead_sub_category,
            province: this.selectedLead.province,
            district: this.selectedLead.district,
            // dsd: this.selectedLead.dsd,
            // gnd: this.selectedLead.gnd,
            dsd: Array.isArray(this.selectedLead.dsd) && this.selectedLead.dsd.length > 0 ? this.selectedLead.dsd.join(',') : '', 
            gnd: Array.isArray(this.selectedLead.gnd) && this.selectedLead.gnd.length > 0 ? this.selectedLead.gnd.join(',') : '', 
            assignAgent: this.selectedLead.agent_name,
            assignDate: new Date(this.selectedLead.assign_date).toISOString().split('T')[0],
            // leadsLists: //here json stringfy the lead list
            leadsLists: selectedLeadsJSON ,
            description: this.lead.description,
            userId,
          };

          axios.post('/api/addLeads', leadData, {
            // headers: {
            //   'Authorization': `Bearer ${token}`,
            //   'content-type': 'multipart/form-data',
            // },
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              'content-type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          })
            .then(response => {
              console.log('Lead added successfully:', response.data);
              this.showSnackbar("Lead added successfully!", "success");
              // Handle successful addition (e.g., clear form, show success message)
            })
            .catch(error => {
              console.error('Error adding lead:', error);
              this.showSnackbar("Error!", "error");
              // Handle error (e.g., show error message)
            });
        },
      
        openReminderDialog(item) {
          this.selectedItem = { ...item };
          console.log("Opening reminder dialog for item:", this.selectedItem );
          this.reminderDialog = true;
        },
       
        sendReminder() {
          // Validate required fields
        if (
          !this.selectedItem.lead_title ||
          !this.selectedItem.institute_name ||
          !this.selectedItem.address ||
          !this.selectedItem.agent_name ||
          !this.selectedItem.mobile_number ||
          !this.selectedItem.email ||
          !this.selectedItem.note
        ) {
          // Show snackbar message
          this.snackAlert.snackMessage = 'Please fill out all required fields correctly.';
          this.snackAlert.snackColor = 'red';
          this.snackAlert.snackVisible = true;
          return; // Stop the function execution if validation fails
        }
          // Extract the required data
          const token = localStorage.getItem('token');
          const userId = localStorage.getItem('userId');
          const reminderData = {
            title: this.selectedLead.lead_title,
            outletName: this.selectedItem.institute_name,
            outletAddress: this.selectedItem.address,
            assignee: this.selectedLead.agent_name,
            mobileNumber: this.selectedLead.mobile_number[0],
            email: this.selectedItem.email,
            note: this.selectedItem.note,
            userID: userId
          };

           // Log the reminder data
              console.log("Reminder sent to:", this.selectedItem);

            // Make API call using axios
            axios.post('/api/reminder', reminderData, {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              }
            })
            .then(response => {
              console.log('Success:', response.data);
              // Handle success response if needed
              this.reminderDialog = false;
            })
            .catch(error => {
              console.error('Error:', error);
              // Handle error response if needed
            });
        },

       // Opens the delete confirmation dialog and sets the selectedLeadId
        confirmDelete(item) {
          this.selectedLeadId = item.id; // Save the ID of the record to delete
          this.deleteLeadDialog = true; // Show the confirmation dialog
        },
        
        // Deletes the selected lead
        deleteItem() {
          const userId = localStorage.getItem('userId');
          const token = localStorage.getItem('token');
          const leadId = this.selectedLeadId;
          console.log('lead id', leadId);

          axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/deleteLead`, {
            userId: userId,
            leadId: leadId,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          })
          .then(() => {
            // Handle success - remove the deleted item from the table data
            this.fetchDataintoTable(); // Refresh the user list
            this.deleteLeadDialog = false; // Close the confirmation dialog
            console.log('Lead deleted successfully');
            this.showSnackbar("Lead delete successfully!", "success");
          })
          .catch((error) => {
            console.log('Error deleting lead', error);
            this.deleteLeadDialog = false;
            this.view_leads_dialog = false;
            this.edit_leads_dialog = false;
            this.fetchDataintoTable();
          });
        },
      }
    };
    </script>
    
    <style scoped>
    .v-card-title {
      font-weight: bold;
    }
    .card-h4-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      color: black;
    }
    .red-text {
      color: #b00d0d;
    }
    .red-light-text {
      background-color: #f1cccc;
      color: #b00d0d;
    }
    .red-white-text {
      background-color: #b00d0d;
      color: white;
    }
    .green-light-text {
      background-color: #aee8b1;
      color: #057b0a;
    }
    .green-text {
      color: #057b0a;
      background-color: #aee8b1;
    }
    .divider-margin-dv{
      margin-top: -20px;
    }
    .avatar-image {
      object-fit: cover;
      width: 100px;
      height: 100px;
      display: block;
    }

    .leads-details-card{
      height: fit-content;
      padding-bottom: 20px;
      margin-bottom: 10px;
      overflow-x: hidden;
      overflow-y: scroll !important;
      max-height: 80vh;

    }

    .image {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      overflow: hidden; 
      position: relative;
      display: flex;
      align-items: center;
      margin-left: 20px;
      margin-right: 20px;
      margin-top: 20px;
    }

    .dataCard{
      display: flex;
      justify-content: left;
    }

    .first_row{
      display: flex;
      justify-content: flex-end;

    }

    .secondC{
      display: flex;

    }

    .textCenter {
      text-align: center;
    }

    .chip1clr{
      background-color: #e4ffe5;
    }
    .chip1clrTwo{
      background-color: #f1cccc;
    }

    .chip2clr{
      background-color: #ffe7d4;
    }

    .rowClr{
      background-color: #d9d9d9;
    }
    .textspace{
      margin-top: -20px;
    }
    .viewbtn{
      font-size: 12px;
    }
    .data-table {
      width: 100% !important;
    }
    :deep(.dataTable) th, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
      text-align: center;
    }

    :deep(.dataTable) td, td{
      border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
      flex: 1;
    }

    :deep(.dataTable) {
      border: white;
    }

    :deep(.dataTable )  tr,  th:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */
    }
    :deep(.dataTable )  tr,  td:last-child {
      border-right: none; /* Removes right border from the last cell in each row and header */
    }
    </style>
    