<template >
    <v-card class="main-card" >
      <v-row class="px-6">

        <v-col class="mt-3" cols="6">
          <h3 class="page-title">ROUTE PLAN LISTS</h3>
          <h5 class="light-gray-text">Analyse the created route list using a variety of parameters.</h5>
          <v-card-title>

          </v-card-title>
        </v-col>

        <v-col class="px-4 mt-5" cols="6">
          <v-row class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-divider :thickness="3" class="divider-vertical" vertical></v-divider>
            <v-btn class="gray-text" size="x-large" variant="text" @click="backPageRoute()">
              <v-icon size="x-large">mdi-menu-left</v-icon>&nbsp;BACK
            </v-btn>
          </v-row>
        </v-col>

      </v-row>
      <v-card height="100%" style=" padding-bottom: 30px; margin-left: 40px; overflow-y: auto;" >
        <h5 class="card-h4-title px-3 mt-2 mb-4 ml-4">ROUTE PLANE DATA ANALYSIS
          <div class="tool-bar-style">
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4 text-red mr-3" variant="outlined" @click="exportPDF">
              <v-icon class="text-red">mdi-file-pdf</v-icon>&nbsp;&nbsp;PDF
            </v-btn>

            <v-btn class="green-light-text" variant="outlined" @click="exportExcel">
              <v-icon class="green-text">mdi-file-excel</v-icon>&nbsp;&nbsp;Excel
            </v-btn>
          </div>
        </h5>

        <v-divider class="pb-6"></v-divider>

      <v-data-table
          :headers="headers"
          :items="filteredRoutes"
          :items-per-page="5"
          :search="search"
          class="data-table"
          style="padding-left: 25px; padding-right: 10px;  padding-bottom: 30px; height: fit-content; margin-bottom: 20px "
      >
        <template v-slot:[`item.agent.name`]="{ item }">
          <div>
            <div>{{ item.agent.name }}</div>
            <div style="color: red">Reg num: {{ item.agent?.registration_num }}</div>
          </div>
        </template>

        <template v-slot:[`item.selected_outlets`]="{ item }">
            <div>
              <v-chip
                v-if="item.overallStatus === 'Visited'"
                color="green"
                label
                style="height: 20px; width:130px; font-size: 12px"
                variant="outlined"
              >
                Visited
                <v-icon class="justify-end ml-14">mdi-check</v-icon>
              </v-chip>

              <v-chip
                v-else
                color="red"
                label
                style="height: 20px; width:130px; font-size: 12px"
                variant="outlined"
              >
                Not Visited
                <v-icon class="justify-end ml-6">mdi-close</v-icon>
              </v-chip>
              <!-- <div class="text-red">Date -{{ item.visited_date }}</div> -->
            </div>
            <div>
            <span class="text-blue">{{ countVisitedOutlets(item) }}</span>
          </div>
          </template>

        <!-- <template v-slot:[`item.route_status`]="{ item }">
          <div>
          <div>
          <v-chip v-if="item.route_status === 'Visited'" color="green" label style="height: 20px; width:130px; font-size: 12px" variant="outlined">
            Visited
            <v-icon class="justify-end ml-14" >mdi-check</v-icon>
          </v-chip>

          <v-chip v-if="item.route_status === 'Not Visited'" color="red" label style="height: 20px; width:130px; font-size: 12px" variant="outlined">
            Not Visited
            <v-icon class="justify-end ml-6" >mdi-close</v-icon>
          </v-chip>
          </div>
            <div v-if="item.route_status === 'Visited'" label style="color: red">Date  -  {{ item.visited_date }}</div>
          </div>
        </template> -->

        <template v-slot:top>
          <v-row>
            <v-col cols="12" md="4">
              <v-date-input
                  v-model="dates"
                  append-icon="mdi-close"
                  color="red"
                  label="Select Date Range"
                  max-width="500"
                  multiple="range"
                  variant="outlined"
                  @click:append="clearDateRange"
              ></v-date-input>

            </v-col>
            <v-col cols="12" md="4">
              <v-select
                  v-model="selectedCategories"
                  :items="categoryOptions"
                  item-title="category_name"
                  label="Select Category"
                  multiple
                  variant="outlined"
                  @change="fetchData"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                  v-model="selectedRouteStatus"
                  :items="routeCategoryOptions"
                  chips
                  label="Route Status"
                  multiple
                  outlined
                  variant="outlined"
                  @change="fetchData"
              ></v-select>
            </v-col>

          </v-row>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-row >
            <v-col cols="12" md="4">
              <v-btn
                  class="mr-1 bg-blue-lighten-4 "
                  color="blue"
                  icon
                  rounded="lg"
                  size="small"
                  variant="outlined"
                  width="120"
                  @click="viewItem(item)"
              >
                <v-icon>mdi-check-circle</v-icon> view
              </v-btn>
            </v-col>
            <v-col class="mr-0 d-flex justify-end" cols="12" md="3">
              <v-btn
                  class="editB bg-green-lighten-4"
                  color="green"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="editItem(item)"

              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
            <v-col class="ml-0" cols="12" md="3">
              <v-btn
                  class="bg-red-lighten-4"
                  color="red"
                  icon
                  rounded
                  size="small"
                  variant="outlined"
                  @click="confirmDelete(item)"
              >
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      </v-card>

      <!-- Delete Confirmation Dialog -->
      <v-dialog v-model="deleteDialog" max-height="500" max-width="500" persistent>
        <v-card class="pa-5">
          <v-row dense style="background-color: #d9d9d9;">
            <v-col cols="12" md="10">
              <v-card-title class="headline">DELETE ROUTE PLAN DATA</v-card-title>
            </v-col>
            <v-col class="d-flex justify-end" cols="12" md="2">
              <v-btn class="mt-2 mr-2" icon size="x-small" @click="deleteDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-4"></v-divider>
          <v-card-text class="text-grey-darken-2"><v-icon class="mr-2" color="orange">mdi-alert</v-icon>Do you want to delete this route plan record ?</v-card-text>
          <v-card-text class="font-weight-medium">
            This route plan record will be deleted immediately. Once deleted, it can no longer be used for any purpose.
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="bg-red-lighten-4" color="red" variant="outlined" @click="deleteItem">DELETE</v-btn>
            <v-btn class="bg-grey-lighten-2" variant="outlined" @click="deleteDialog = false">CANCEL</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="view_Dialog" class="solid-dialog" fullscreen persistent >
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row>
            <RoutePlan/>
          </v-row>
        </v-card>
      </v-dialog >


      <v-dialog v-model="editDialog" class="solid-dialog" fullscreen persistent >
        <v-card class="card-size">
          <v-toolbar class="pr-5 mb-5" color="white" dense>
            <v-toolbar-title>
              <img :src="require('@/assets/app_bar_logo.png')" @click="homeReload()"/>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="#b00d0d" dark variant="elevated" @click="closeD">
              <v-icon>mdi-close-circle-outline</v-icon>&nbsp;&nbsp;CLOSE
            </v-btn>
          </v-toolbar>
          <v-divider class="divider-margin-dv"></v-divider>
          <v-row>
            <RoutePlanEdit/>
          </v-row>
        </v-card>
      </v-dialog >

    </v-card>
</template>

<script>
import axios from 'axios';
import { EventBus } from '@/plugins/event-bus';
import RoutePlan from "@/components/RouteManagement/RoutePlan.vue";
import RoutePlanEdit from "@/components/RouteManagement/RoutePlanEdit.vue";
import {mapActions} from "vuex";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from "xlsx";


export default {
  name: "RoutesList",
  components: {
    RoutePlanEdit,
    RoutePlan,
  },
  data() {
    return {
      menu: false,
      dates: [],
      dateRange: '',
      search: '',
      deleteDialog: false,
      itemToDelete: null,
      view_Dialog: false,
      editDialog: false,
      showSwitches: false,
      SalesSwitch: false,
      CollectionsSwitch: false,
      selectedCategories: [],
      categoryOptions: [],
      selectedRouteStatus: [],
      routeCategoryOptions: ['Visited', 'Not Visited'],
      headers: [
        { text: 'Category', value: 'route_category', title: 'CATEGORY' },
        { text: 'Agent Name', value: 'agent.name', title: 'AGENT NAME'  },
        { text: 'Route Name', value: 'route_name', title: 'ROUTE NAME' },
        { text: 'Route Status', value: 'selected_outlets', title: 'ROUTE STATUS' },
        { text: 'Assigned Date', value: 'assign_date', title: 'ASSIGN DATE' },
        { text: 'Actions', value: 'action', sortable: false, title: 'ACTION' },
      ],
      routes: [], // Initially empty, will be filled by API call
      id : '',

    };
  },
  methods: {
    ...mapActions(['updateSharedDataRoutes']),

    countVisitedOutlets(route) {
    if (!route.selected_outlets || route.selected_outlets.length === 0) {
      return "0/0"; // No outlets case
    }

    const totalOutlets = route.selected_outlets.length;
    const visitedOutlets = route.selected_outlets.filter(outlet => outlet.status === '"Visited"').length;
    return `${visitedOutlets}/${totalOutlets}`; 
  },

    fetchRoutes() {

      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes`,{userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
        }
        })

          .then(response => {
            this.routes = response.data;
            console.log("API Response:", response.data);
          })
          .catch(error => {
            console.error('Error fetching routes:', error);
          });
        },

    confirmDelete(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    deleteItem() {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId');
      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/delete/${this.itemToDelete.id}`, {userId}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(() => {
            this.routes = this.routes.filter(route => route.id !== this.itemToDelete.id);
            this.deleteDialog = false;
            this.itemToDelete = null;
          })
          .catch(error => {
            console.error('Error deleting route:', error);
          });
    },
    updateDateRange() {
      if (this.dates.length === 2) {
        this.dateRange = `${this.dates[0]} - ${this.dates[1]}`;
      }
    },
    exportPDF() {
      const doc = new jsPDF();

      // Set the title for the document
      doc.text('Collection Analysis', 14, 10);

      // Prepare the data for the table
      const tableData = this.routes.map((item, index) => [
        index + 1,
        item.route_category,
        item.agent.name,
        item.route_name,
        item.route_status,
        item.assign_date,

      ]);

      // Define the columns for the table
      const tableColumns = ['#', 'Category', 'Agent Name', 'Route Name', 'Route Status', 'Assigned Date'];

      // AutoTable plugin to create a table
      doc.autoTable({
        head: [tableColumns],
        body: tableData,
      });

      // Save the PDF with a name
      doc.save('Route_Details.pdf');

    },
    exportExcel() {
      const tableData = this.routes.map((item, index) => ({
        '#': index + 1,
        'Category': item.route_category,
        'Agent Name': item.agent.name,
        'Route Name': item.route_name,
        'Route Status':  item.route_status,
        'Assigned Date':   item.assign_date
      }));

      const worksheet = XLSX.utils.json_to_sheet(tableData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Route Details');


      XLSX.writeFile(workbook, 'Route_Details.xlsx');

    },
    viewItem(item) {
      // Functionality to view item details

      console.log('items',item)
      this.id = item.id
      this.updateSharedDataRoutes(item.id);
      this.view_Dialog = true;
    },
    editItem(item) {
      // Functionality to edit item details
      this.updateSharedDataRoutes(item.id);
      this.editDialog = true;
    },
    closeD() {
      this.view_Dialog = false;
      this.editDialog = false;
    },
    backPageRoute(){
      this.$router.push('/dashboard');
    },
    roteManagementButtonClick() {
      // Logic to execute when the second component button is clicked
      this.view_Dialog = false;
      this.editDialog = true;
    },
    r2(){
      this.editDialog = false;
      this.view_Dialog = false;
      this.routes = this.routes.filter(route => route.id !== this.id);
      this.id = null;
      window.location.reload();
    },
    setupEventListeners() {
      EventBus.on('routeRecodeManagementButtonClick', this.roteManagementButtonClick);
      EventBus.on('routeRecodeManagementButtonClick2', this.r2);
    },
    removeEventListeners() {
      EventBus.off('routeRecodeManagementButtonClick', this.roteManagementButtonClick);
      EventBus.off('routeRecodeManagementButtonClick2', this.r2);
    },
    toggleSwitches() {
      this.showSwitches = !this.showSwitches;  // Toggle the visibility of the switches
    },
    fetchMainCategories() {
      const userId = localStorage.getItem('userId');
      const token = localStorage.getItem('token');

      axios.post(`${process.env.VUE_APP_API_BASE_URL}/api/routes/routes/categories`, { userId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      })
          .then(response => {
            this.categoryOptions = response.data;
            console.log("API Response category list:", response.data);
          })
          .catch(error => {
            console.error('Error fetching main categories:', error);
          });

    },
    clearDateRange() {
      this.dates = []; // Clear the date range
    },
  },
  mounted() {
    this.setupEventListeners();
    this.fetchRoutes(); // Fetch routes when component is mounted
    this.fetchMainCategories();
  },
  beforeUnmount() {
    this.removeEventListeners();
  },
  computed: {
    filteredRoutes() {
      return this.routes.filter((route) => {  
        const formatDate = (date) => {
          const d = new Date(date);
          return d.toISOString().split('T')[0];
        };

        const startDate = this.dates.length ? formatDate(this.dates[0]) : null;
        const endDate = this.dates.length ? formatDate(this.dates[this.dates.length - 1]) : null;

        // Show all routes when no date range is selected
        const withinDateRange =
            !startDate || !endDate ||
            (route.assign_date &&
                formatDate(route.assign_date) >= startDate &&
                formatDate(route.assign_date) <= endDate);

        const matchesCategory =
            !this.selectedCategories.length ||
            this.selectedCategories.includes(route.category);

        const matchesRouteStatus =
            !this.selectedRouteStatus.length ||
            this.selectedRouteStatus.includes(route.routeStatus);

            // Check if all outlets are visited
            const allOutletsVisited = route.selected_outlets.every(outlet => outlet.status === 'Visited');
            const overallStatus = allOutletsVisited ? 'Visited' : 'Not Visited';
            route.overallStatus = overallStatus;

        return withinDateRange && matchesCategory && matchesRouteStatus;
      });
    },
  },
  watch: {
    dates() {
      this.updateDateRange();
    }
  },
}
</script>


<style scoped >

.main-card{
  width:100%;
  height: 100%;
  padding-right: 20px;
}


:deep(.data-table) th, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
  text-align: center;
}

:deep(.data-table) td, td{
  border-right: 2px solid lightgrey; /* Adds right border to each cell and header */
  flex: 1;
}

:deep(.data-table) {
  border: white;
}

:deep(.data-table )  tr,  th:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}
:deep(.data-table )  tr,  td:last-child {
  border-right: none; /* Removes right border from the last cell in each row and header */
}

.v-dialog > .v-overlay__content > .v-card, .v-dialog > .v-overlay__content > .v-sheet, .v-dialog > .v-overlay__content > form > .v-card, .v-dialog > .v-overlay__content > form > .v-sheet {
  overflow-y: hidden;
}




</style>